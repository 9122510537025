import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Input, Table, Tooltip } from 'antd'
import { PlusSquareOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { PeopleAdd24Regular, Delete24Regular, Copy24Regular } from '@fluentui/react-icons'
import { getPlatformURL } from '@/helpers/env'
import { showToast } from '@/utils/toast/index'
import Button from '@/components/Button'
import MemberAvatar from '@/components/MemberAvatar'
import TeamIcon, { teamIcons } from '@/pages/MainPage/components/TeamIcon'
import {
  setTeamModalOpen,
  createAccountTeam,
  updateAccountTeam,
  removeAccountTeam,
  setAccountTeam,
  addTeamUsers,
  createAccountInviteLink,
} from '@/store/accounts/actions'
import { Modal, ColorBox } from './styles'

const TeamModal = () => {
  const dispatch = useDispatch()

  const { themeConfig } = useSelector((state) => state.app)
  const {
    currentAccount: account,
    currentTeam: team,
    allUsers,
    currentInviteLink: inviteLink,
    isTeamModalOpen,
    isTeamsLoading,
  } = useSelector((state) => state.accounts)

  const colors = [
    themeConfig?.colors?.red6,
    themeConfig?.colors?.orange6,
    themeConfig?.colors?.gold6,
    themeConfig?.colors?.yellow6,
    themeConfig?.colors?.lime6,
    themeConfig?.colors?.green6,
    themeConfig?.colors?.cyan6,
    themeConfig?.colors?.geekblue6,
    themeConfig?.colors?.purple6,
    themeConfig?.colors?.magenta6,
  ]

  const [name, setName] = useState('')
  const [color, setColor] = useState(colors[0])
  const [icon, setIcon] = useState(teamIcons[0].name)
  const [members, setMembers] = useState([])
  const [isInviteLinkLoading, setIsInviteLinkLoading] = useState(false)

  const inviteFulfillmentIsEnabled = account?.config?.fulfillment?.invite

  const isCreateModal = (!team || isTeamModalOpen === 'create') && !team?.isJustCreated
  const isJustCreated = isTeamModalOpen === 'create' && team?.isJustCreated
  const isEditModal = isTeamModalOpen === 'edit'
  const isInviteModal = isTeamModalOpen === 'invite'
  const isDeleteModal = isTeamModalOpen === 'delete'

  const inviteLinkUrl = `${getPlatformURL(account?.slug)}invite/${inviteLink?.id?.split('-')?.[0]}`

  // users that are not in the team yet
  const availableUser = allUsers?.filter((userData) => !team?.users?.map((u) => u.id)?.includes(userData?.user?.id))

  const handleSubmit = () => {
    const teamData = {
      name,
      color,
      icon,
    }

    if (!name) {
      showToast('Team name is required', 'error')
      return
    }

    if (team) {
      dispatch(updateAccountTeam(account?.id, team?.id, teamData, () => dispatch(setTeamModalOpen(false))))
      return
    }

    dispatch(createAccountTeam(account?.id, teamData, (team) => dispatch(setAccountTeam(team))))
  }

  const handleDelete = () => {
    dispatch(removeAccountTeam(account?.id, team?.id, () => dispatch(setTeamModalOpen(false))))
  }

  const handleAddMembersToTeam = () => {
    dispatch(addTeamUsers(account?.id, team?.id, { user_ids: members }, () => dispatch(setTeamModalOpen(false))))
  }

  const handleCreateInviteLink = async () => {
    setIsInviteLinkLoading(true)
    await dispatch(createAccountInviteLink(account?.id, { teams: [team?.id] }))
    setIsInviteLinkLoading(false)
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(inviteLinkUrl)
    showToast('Link copied to clipboard')
  }

  useEffect(() => {
    if (!team) return

    setName(team?.name)
    setColor(team?.color)
    setIcon(team?.icon)
    setMembers([])
  }, [team])

  useEffect(() => {
    if (!isTeamModalOpen) {
      setName('')
      setColor(colors[0])
      setIcon(teamIcons[0].name)
      setMembers([])
      dispatch(setAccountTeam())
    }

    if (isInviteModal) {
      handleCreateInviteLink()
    }
  }, [isTeamModalOpen])

  return (
    <Modal
      className="team-modal"
      open={!!isTeamModalOpen}
      onCancel={() => dispatch(setTeamModalOpen(false))}
      footer={null}
    >
      <div className="container">
        <div className="header">
          {isCreateModal && (
            <>
              <PeopleAdd24Regular className="icon" />
              <h3 className="title">Create a new team</h3>
            </>
          )}

          {isEditModal && (
            <>
              <PeopleAdd24Regular className="icon" />
              <h3 className="title">Edit your team</h3>
            </>
          )}

          {isDeleteModal && (
            <>
              <Delete24Regular className="icon error" />
              <h3 className="title">
                Are you sure you want to delete the ‘<span className="team-name">{team?.name}</span>’ team?
              </h3>
              <p className="text">You won't be able to revert this action.</p>
            </>
          )}

          {isInviteModal && (
            <>
              <PeopleAdd24Regular className="icon" />
              <h3 className="title">Add or invite members to the team</h3>
            </>
          )}

          {isJustCreated && (
            <>
              <CheckCircleOutlined className="icon success" />
              <h3 className="title">Your team has been successfully created.</h3>
            </>
          )}
        </div>

        <div className="content">
          {(isCreateModal || isEditModal) && (
            <>
              <div className="section">
                <p className="section-title">Customize your team</p>

                <Input
                  className="name-input"
                  ref={(el) => {
                    setTimeout(() => el?.focus(), 0)
                  }}
                  size={'large'}
                  value={name}
                  onChange={(evt) => setName(evt.target.value)}
                  placeholder="Add a name"
                  disabled={isTeamsLoading}
                />
              </div>

              <div className="section">
                <p className="section-title">Select a color</p>

                <div className="colors-container">
                  {colors?.map((c, i) => (
                    <ColorBox
                      key={`color-box-${i}`}
                      className="color-box"
                      $color={c}
                      $isCurrent={color === c}
                      onClick={() => setColor(c)}
                    />
                  ))}
                </div>
              </div>

              <div className="section">
                <p className="section-title">Select an icon</p>

                <div className="icons-container">
                  {teamIcons?.map((iconData, i) => (
                    <TeamIcon
                      key={`icon-box-${i}`}
                      color={color}
                      iconName={iconData?.name}
                      isCurrent={icon === iconData?.name}
                      onClick={() => setIcon(iconData?.name)}
                    />
                  ))}
                </div>
              </div>
            </>
          )}

          {isInviteModal && (
            <>
              <hr className="divider" />

              <div className="section">
                {availableUser?.length ? (
                  <Table
                    className="members-table"
                    size={'small'}
                    columns={[
                      {
                        title: 'Member',
                        dataIndex: 'member',
                      },
                    ]}
                    dataSource={availableUser?.map((userData) => ({
                      key: userData?.user?.id,
                      member: (
                        <MemberAvatar
                          lastName={userData?.user?.last_name}
                          firstName={userData?.user?.first_name}
                          showName
                          avatarUrl={userData?.user?.avatar_url}
                          externalId={userData?.user?.external_id}
                        />
                      ),
                    }))}
                    rowSelection={{
                      selectedRowKeys: members,
                      onChange: (selectedRows) => setMembers(selectedRows),
                    }}
                    showHeader={false}
                    pagination={false}
                  />
                ) : (
                  <p className="text">All the members are already on the team.</p>
                )}
              </div>

              <div className="section">
                <p className="section-title">Or use this link to invite people to the team</p>

                <Input.Search
                  className="link-input"
                  placeholder={isInviteLinkLoading ? 'Generating invite link...' : inviteLinkUrl}
                  size="large"
                  readOnly
                  onSearch={handleCopyToClipboard}
                  loading={isInviteLinkLoading}
                  enterButton={
                    !isInviteLinkLoading && (
                      <Tooltip title="Copy to clipboard" overlayClassName={'on-modal'}>
                        <Copy24Regular className="icon copy-icon" />
                      </Tooltip>
                    )
                  }
                />
              </div>
            </>
          )}

          <div className={`actions ${isDeleteModal || isJustCreated ? 'center' : ''}`}>
            {(isCreateModal || isEditModal) && (
              <Button className="submit-button" type="secondary" onClick={handleSubmit} loading={isTeamsLoading}>
                <PlusSquareOutlined /> {isCreateModal ? 'Create team' : 'Edit team'}
              </Button>
            )}

            {isDeleteModal && (
              <>
                <Button className="close-button" onClick={() => dispatch(setTeamModalOpen(false))}>
                  Cancel
                </Button>

                <Button className="submit-button" type="primary" danger onClick={handleDelete} loading={isTeamsLoading}>
                  Delete
                </Button>
              </>
            )}

            {isJustCreated && (
              <>
                {inviteFulfillmentIsEnabled && (
                  <Button
                    className="submit-button"
                    type="secondary"
                    onClick={() => dispatch(setTeamModalOpen('invite'))}
                    loading={isTeamsLoading}
                  >
                    <PlusSquareOutlined /> Invite members
                  </Button>
                )}

                <Button className="close-button" onClick={() => dispatch(setTeamModalOpen(false))}>
                  Done
                </Button>
              </>
            )}

            {isInviteModal && !!availableUser?.length && (
              <Button
                className="submit-button"
                type="secondary"
                onClick={handleAddMembersToTeam}
                loading={isTeamsLoading}
              >
                <PlusSquareOutlined /> Add members
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default TeamModal
