import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'antd'
import { setWelcomeModalOpen } from '@/store/accounts/actions'
import { Modal } from './styles'

const WelcomeModal = () => {
  const dispatch = useDispatch()

  const { isWelcomeModalOpen } = useSelector((state) => state.accounts)
  const VIDEO_URL = 'https://www.loom.com/embed/ff8c1516aec341d3a09398bf5c9abdd2'

  return (
    <Modal
      className="welcome-modal"
      title="Watch quickstart — First steps on DataWars Teams platform"
      open={isWelcomeModalOpen}
      onCancel={() => dispatch(setWelcomeModalOpen(false))}
      footer={null}
      centered
      width={1200}
      destroyOnClose
    >
      <div className="content">
        <div className="loading-container">
          <Spin size="large" />
          <p className="text">Loading video...</p>
        </div>

        <iframe
          title="datawars.io"
          src={`${VIDEO_URL}?autoplay=1&muted=0&hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`}
        ></iframe>
      </div>
    </Modal>
  )
}

export default WelcomeModal
