import { algoliasearch } from 'algoliasearch'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const client = algoliasearch(
  import.meta.env.REACT_APP_ALGOLIA_APP_ID,
  import.meta.env.REACT_APP_ALGOLIA_SEARCH_ONLY_KEY,
)

const getAlgoliaSkillTracks = async (filter) => {
  const baseFilter = "title.name:'Data Scientist with Python'"

  return client
    .searchSingleIndex({
      indexName: import.meta.env.REACT_APP_ALGOLIA_SKILLTRACKS_INDEX,
      searchParams: { query: '', filters: filter ? `${baseFilter} AND ${filter}` : baseFilter, hitsPerPage: 100 },
    })
    .then((response) => {
      const auxData = response?.hits?.map((item) => ({
        ...item,
        id: item?.objectID,
      }))

      return Promise.resolve(auxData)
    })
    .catch((error) => Promise.reject(apiErrorHandler(error)))
}

export { getAlgoliaSkillTracks }
