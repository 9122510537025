import { isDown } from '@/themes/breakpoints'
import TeamIcon from '@/pages/MainPage/components/TeamIcon'
import { Container } from './styles'

const TeamItem = ({ team, size, icon, text }) => {
  return (
    <Container className="team-item-container" $isTeam={!!team} $size={size}>
      <TeamIcon color={team?.color} iconName={team?.icon} icon={icon} size={isDown('md') ? 'xsmall' : size} />
      <p className="name">{team?.name || text}</p>
    </Container>
  )
}

export default TeamItem
