import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  overflow: auto;

  > .main-content {
    height: 100%;
    width: 100%;

    .header-container {
      width: 100%;
      height: 100%;
      background-color: ${(props) => props.theme.colors.geekblue1};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  @media ${device.lgDown} {
    flex-direction: column-reverse;
    align-items: center;
  }
`

export const MenuContainer = styled.div`
  min-width: 84px;
  max-width: 84px;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.geekblue1 : props.theme.colors.gray1};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: 0px 2px 8px 0px
    ${(props) => (localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray7 : props.theme.colors.gray13)}26;
  z-index: 1;

  .top-actions {
    height: calc(100% - 77px);
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    .account-selector {
      height: 76px;
      max-width: 84px;
      width: 100%;
      color: ${(props) => props.theme.colors.gray1};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .account-image-container {
        width: 42px;
        height: 42px;
        background: linear-gradient(86deg, #39476d 8.49%, #4150d0 67.75%, rgba(179, 114, 244, 0.62) 97.13%);
        border-radius: 7px;
        overflow: hidden;
        box-shadow: 0px 1px 4px 2px ${(props) => props.theme.colors.gray6}66;

        .account-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          pointer-events: none;
        }
      }
    }

    .account-menu {
      background-color: transparent;
      display: flex;
      flex-direction: column;
      gap: 4px;
      overflow: auto;
      border-inline-end: none !important;

      .ant-menu-item {
        min-height: 76px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border: none !important;
        padding: 0px !important;
        margin: 0px;
        user-select: none;

        .ant-menu-item-icon,
        .ant-menu-item-icon svg {
          width: 20px;
          height: 20px;
          justify-content: center;

          path {
            fill: ${(props) => props.theme.colors.gray7};
          }
        }

        .ant-menu-item-icon svg {
          padding: 2px;
        }

        .ant-menu-title-content {
          width: 100%;
          color: ${(props) => props.theme.colors.gray7};
          flex: initial;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          padding: 0px 4px;
          margin: 0px;
        }

        &.highlight {
          background: linear-gradient(
              90deg,
              ${(props) => props.theme.colors.purple2} 0%,
              ${(props) => props.theme.colors.gray1} 130%
            ),
            #f0f2f5;
        }

        &:hover {
          background-color: ${(props) =>
            localStorage.getItem('dw-theme') === 'dark'
              ? props.theme.colors.blue2
              : props.theme.colors.gray3} !important;
        }

        &.ant-menu-item-selected {
          background-color: ${(props) =>
            localStorage.getItem('dw-theme') === 'dark'
              ? props.theme.colors.blue3
              : props.theme.colors.blue1} !important;

          .ant-menu-title-content {
            color: ${(props) =>
              localStorage.getItem('dw-theme') === 'dark'
                ? props.theme.colors.gray13
                : props.theme.colors.blue5} !important;
          }

          .ant-menu-item-icon,
          .ant-menu-item-icon svg {
            path {
              fill: ${(props) =>
                localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray13 : props.theme.colors.blue6};
            }
          }

          .ant-menu-title-content {
            color: ${(props) => props.theme.colors.geekblue5};
          }
        }

        &.ant-menu-item-disabled {
          svg {
            path {
              fill: ${(props) => props.theme.colors.disabledText};
            }
          }
        }
      }

      &.ant-menu-inline-collapsed {
        .ant-menu-item {
          padding: 0px;
        }
      }
    }
  }

  .bottom-actions {
    height: 77px;
    border-top: 1px solid ${(props) => props.theme.colors.gray4};

    .menu-item {
      height: 76px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 0px 4px;
      margin: 0px;
      user-select: none;
      transition: all 0.3s;
      cursor: pointer;

      .icon {
        color: ${(props) => props.theme.colors.gray7};
        width: 20px;
        height: 20px;
      }

      .text {
        width: 100%;
        color: ${(props) => props.theme.colors.gray7};
        flex: initial;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0px;
      }

      &:hover {
        background-color: ${(props) =>
          localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.blue2 : props.theme.colors.gray3};
      }
    }
  }

  @media ${device.lgDown} {
    height: 64px;
    max-width: initial;
    flex-direction: row;
    padding: 0px;

    .top-actions {
      height: auto;
      flex-direction: row;

      .account-selector {
        height: 100%;
        max-width: 62px;
        margin: 0px;
      }

      .account-menu {
        flex-direction: row;
        flex: 1 1 100%;

        .ant-menu-item {
          height: auto;
          min-height: auto;

          .ant-menu-item-icon,
          .ant-menu-item-icon svg {
            height: 28px !important;
            width: 28px !important;
          }
        }
      }
    }

    .bottom-actions {
      height: auto;

      .menu-item {
        height: 100%;
        padding: 0px 24px;

        .icon {
          height: 28px;
          width: 28px;
        }

        .text {
          display: none;
        }
      }
    }
  }
`
