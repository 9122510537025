import { useSelector } from 'react-redux'
import { getPlatformURL } from '@/helpers/env'
import Layout1 from '@/layouts/Layout1'
import Button from '@/components/Button'
import ErrorImage from '@/assets/images/error.gif'
import { Container } from './styles'

const NotFoundPage = ({ title, text, image }) => {
  const { currentAccount } = useSelector((state) => state.accounts)

  const selfLaunchIsEnabled = currentAccount?.config?.launch?.self_launch

  const goToLearnPlatform = () => {
    window.location.replace(getPlatformURL(currentAccount?.slug))
  }

  return (
    <Layout1 navbar>
      <Container>
        <div className="not-found-card-container">
          <img src={image || ErrorImage} alt={title || 'Not found'} />

          <h3 className="title">{title || 'Oops! Page not found'}</h3>
          <p className="text-body">
            {text || (
              <>
                The page you are looking for can't be found. <br />
                Check the web address and try again.
              </>
            )}
          </p>

          <div className="actions">
            {selfLaunchIsEnabled && (
              <Button className="button" type="primary" onClick={goToLearnPlatform}>
                Go to Learn platform
              </Button>
            )}
          </div>
        </div>
      </Container>
    </Layout1>
  )
}

export default NotFoundPage
