import { algoliasearch } from 'algoliasearch'
import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const client = algoliasearch(
  import.meta.env.REACT_APP_ALGOLIA_APP_ID,
  import.meta.env.REACT_APP_ALGOLIA_SEARCH_ONLY_KEY,
)

const getAlgoliaModules = async (filter) => {
  return client
    .searchSingleIndex({
      indexName: import.meta.env.REACT_APP_ALGOLIA_MODULES_INDEX,
      searchParams: { query: '', filters: filter, hitsPerPage: 100 },
    })
    .then((response) => {
      const auxData = response?.hits?.map((item) => ({
        ...item,
        id: item?.objectID,
      }))

      return Promise.resolve(auxData)
    })
    .catch((error) => Promise.reject(apiErrorHandler(error)))
}

const postModuleAttemptComment = async (moduleId, moduleAttemptId, data) => {
  const route = `modules/${moduleId}/attempts/${moduleAttemptId}/comments`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const patchModuleAttemptComment = async (moduleId, moduleAttemptId, commentId, data) => {
  const route = `modules/${moduleId}/attempts/${moduleAttemptId}/comments/${commentId}`
  return API.patch(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const deleteModuleAttemptComment = async (moduleId, moduleAttemptId, commentId) => {
  const route = `modules/${moduleId}/attempts/${moduleAttemptId}/comments/${commentId}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export { getAlgoliaModules, postModuleAttemptComment, patchModuleAttemptComment, deleteModuleAttemptComment }
