import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import Button from '@/components/Button'
import LeaderboardTable from '@/pages/MainPage/components/LeaderboardTable'
import { setInviteModalOpen } from '@/store/accounts/actions'
import { Container } from './styles'

const MembersCard = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { currentAccount: account, allUsers, isLoading: isAccountsLoading } = useSelector((state) => state.accounts)
  const inviteFulfillmentIsEnabled = account?.config?.fulfillment?.invite

  const renderEmptyState = () => {
    return (
      <div className="content empty-state">
        <div className="header">
          <div className="container">
            <UserOutlined className="icon" />

            <h4 className="title">You don't have any members yet.</h4>

            {inviteFulfillmentIsEnabled && (
              <>
                <p className="subtitle">Invite them join now!</p>

                <Button type="secondary" onClick={() => dispatch(setInviteModalOpen(true))}>
                  Invite members
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }

  const renderMembers = () => {
    return (
      <>
        <div className="header">
          <div className="title-container">
            <UserOutlined className="icon" />
            <p className="title">Members</p>
          </div>

          <Button className="cta-button" type="secondary" onClick={() => navigate(`/accounts/${account?.id}/members`)}>
            View all members
          </Button>
        </div>

        <div className="users-info">
          <div className="main-circle">
            <h2 className="title">{allUsers?.length}</h2>
            <p className="text">Total members</p>

            <div className="circle" />
            <div className="circle" />
            <div className="circle" />
            <div className="circle" />
            <div className="circle" />
          </div>
        </div>
      </>
    )
  }

  const renderLeaderboard = () => {
    return (
      <div className="leaderboard-container">
        <LeaderboardTable size={'middle'} isPreview />

        <Button className="cta-button" type="secondary" onClick={() => navigate(`/accounts/${account?.id}/analytics`)}>
          View Leaderboard
        </Button>
      </div>
    )
  }

  return (
    <Container className="card members-card">
      {isAccountsLoading && allUsers === null ? (
        <div className="loading-content">
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
          <Skeleton className="members-chart" active title={false} paragraph={{ rows: 1 }} />
          <Skeleton className="members-table" active title={false} paragraph={{ rows: 1 }} />
        </div>
      ) : allUsers?.length ? (
        <div className="content">
          {renderMembers()}

          {renderLeaderboard()}
        </div>
      ) : (
        renderEmptyState()
      )}
    </Container>
  )
}

export default MembersCard
