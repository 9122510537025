import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .loading-container {
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 14px;
    flex: none;
  }

  > .no-content {
    margin-top: 40px;

    .ant-empty-description {
      color: ${(props) => props.theme.colors.gray7};
    }
  }

  > .header {
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto hidden;
    padding: 12px 24px;

    .search-box {
      min-width: 200px;
      max-width: 300px;
      width: 100%;
      background-color: ${(props) => props.theme.colors.gray1};
      border: 1px solid ${(props) => props.theme.colors.base.gray5};
      border-radius: 2px;
      position: relative;
      display: flex;
      align-items: center;

      .icon {
        height: 100%;
        width: 31px;
        background-color: ${(props) => props.theme.colors.gray2};
        border-left: 1px solid ${(props) => props.theme.colors.gray5};
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0px;
        right: 0px;

        svg {
          color: ${(props) => props.theme.colors.gray13}40;
          font-size: 16px;
        }
      }

      .ais-SearchBox {
        width: 100%;

        input {
          width: 100%;
          font-size: 14px;
          border: 0px;
          outline: 0px;
          padding: 7px 38px 7px 10px;
        }

        .ais-SearchBox-loadingIndicator,
        .ais-SearchBox-submit,
        .ais-SearchBox-reset {
          display: none;
        }
      }
    }
  }

  > .body {
    > .content {
      .collections-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 60px;

        > div > .ant-collapse-header {
          .ant-collapse-expand-icon svg {
            height: 18px;
            width: 18px;
          }

          .title {
            margin: 0px;
          }
        }

        .collection-container {
          background-color: ${(props) => props.theme.colors.gray1};
          border: 1px solid ${(props) => props.theme.colors.gray5};
          border-radius: 0px;

          .ant-collapse-content-box {
            padding: 0px;
          }

          .collection-content {
            .section-container {
              .ant-collapse-item {
                .ant-collapse-header {
                  background-color: ${(props) => props.theme.colors.gray2};
                  border-top: 1px solid ${(props) => props.theme.colors.gray5};
                  border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
                  border-radius: 0px;
                  margin-bottom: -1px;

                  .ant-collapse-expand-icon svg {
                    height: 12px;
                    width: 12px;
                  }

                  .level-title {
                    color: ${(props) => props.theme.colors.gray10};
                    margin: 0px;
                  }
                }

                &.ant-collapse-item-active {
                  margin-bottom: 0px;
                }
              }

              .ant-collapse-content-box {
                padding: 12px;

                .content-container {
                  border-bottom: 1px solid ${(props) => props.theme.colors.gray4};

                  &:last-child {
                    border-bottom: none;
                  }
                }
              }
            }

            .no-results-box {
              display: flex;
              flex-direction: column;
              padding: 12px 20px;

              & > img {
                user-select: none;
                pointer-events: none;
              }

              .title {
                margin-bottom: 6px;
              }

              .text {
                color: ${(props) => props.theme.colors.gray7};
                font-weight: 500;
              }
            }
          }
        }

        &:not(:last-child) {
          .collection-container {
            border-bottom: none;
          }
        }
      }
    }
  }
`
