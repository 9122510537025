import styled, { css } from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;

  &.is-loading {
    max-width: 800px;
    width: 100%;

    .ant-skeleton {
      margin: 0px;

      .ant-skeleton-paragraph {
        margin: 0px;

        li {
          width: 100% !important;
        }
      }
    }
  }

  > .image-container {
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
    background: linear-gradient(
      250deg,
      ${(props) => props.theme.colors.base.geekblue4} 0%,
      ${(props) => props.theme.colors.base.gray12} 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    .main-icon {
      width: 32px;
      height: 32px;
      color: ${(props) => props.theme.colors.base.gray1};
      font-size: 32px;
    }

    ${(props) =>
      props.$playgroundBackgroundImage &&
      css`
        background-image: url(${(props) => props.$playgroundBackgroundImage});
        background-size: cover;
        background-position: center;
        object-fit: cover;

        .main-icon {
          display: none;
        }
      `}
  }

  > .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .actions {
      display: flex;
      align-items: center;
      gap: 10px;

      .back-button {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
      }
    }

    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 0px;
    }
  }

  @media ${device.smDown} {
    > .image-container {
      min-width: 54px;
      max-width: 54px;
      min-height: 54px;
      max-height: 54px;

      .main-icon {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }
  }
`
