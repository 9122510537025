import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { PersonArrowRight24Regular, Copy24Regular } from '@fluentui/react-icons'
import { getPlatformURL } from '@/helpers/env'
import { showToast } from '@/utils/toast'
import Button from '@/components/Button'
import ModuleBox from '@/components/ModuleBox'
import { setAssignModalOpen } from '@/store/modules/actions'
import GoogleClassromIcon from '@/assets/images/icons/google-classroom.png'
import MicrosoftTeamsIcon from '@/assets/images/icons/microsoft-teams.png'
import { Modal } from './styles'

const AssignModal = () => {
  const dispatch = useDispatch()

  const { currentAccount } = useSelector((state) => state.accounts)
  const { isAssignModalOpen } = useSelector((state) => state.modules)

  const selfLaunchIsEnabled = currentAccount?.config?.launch?.self_launch

  const module = isAssignModalOpen
  const isAssessment = module?.module_type === 'assessment'
  const contentUrl = module
    ? `${getPlatformURL(currentAccount?.slug)}${isAssessment ? 'assessment' : 'project'}/${module?.id}`
    : null
  const googleClassroomURL = module
    ? `https://classroom.google.com/share?url=${contentUrl}&title=${module?.name}&body=${module?.description}&itemtype=assignment`
    : null

  const microsoftTeamsURL = module
    ? `https://teams.microsoft.com/share?href=${contentUrl}&msgText=${"Here's a new assignment"}&assignTitle=${module?.name}&assignInstr=${module?.description}`
    : null

  const copyToClipboard = () => {
    if (selfLaunchIsEnabled) {
      navigator.clipboard.writeText(contentUrl)
      showToast('Link copied to clipboard')
    } else {
      navigator.clipboard.writeText(module?.id)
      showToast(`${isAssessment ? 'Assessment' : 'Project'} ID copied to clipboard`)
    }
  }

  const cleanup = () => {
    dispatch(setAssignModalOpen(false))
  }

  return (
    <Modal open={isAssignModalOpen} onCancel={cleanup} footer={null} destroyOnClose>
      <div className="container">
        <h4 className="title">
          <PersonArrowRight24Regular className="icon" />
          Assign the following project
        </h4>

        <ModuleBox module={module} disableClick />

        <p className="text">Assign this project using either Google Classrooms or with a direct link below.</p>

        <div className="quote">
          <p className="text">
            Once your members start their work on this project, you'll be able to see their progress in the Analytics
            section.
          </p>
        </div>

        <div className="actions">
          {selfLaunchIsEnabled && (
            <>
              <Link className="share-google-classroom-btn" to={googleClassroomURL} target="_blank">
                <Button
                  type="default"
                  icon={<img className="icon" src={GoogleClassromIcon} alt="Add to Google Classroom" />}
                >
                  Add to Google Classroom
                </Button>
              </Link>

              <Link className="share-microsoft-teams-btn" to={microsoftTeamsURL} target="_blank">
                <Button
                  type="default"
                  icon={<img className="icon" src={MicrosoftTeamsIcon} alt="Add to Microsoft Teams" />}
                >
                  Add to Microsoft Teams
                </Button>
              </Link>
            </>
          )}

          <Button className="copy-link-button" type="primary" onClick={copyToClipboard}>
            <Copy24Regular className="icon" /> Copy {isAssessment ? 'assessment' : 'project'}{' '}
            {selfLaunchIsEnabled ? 'link' : 'ID'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AssignModal
