import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { isDataWarsHostName } from '@/helpers/env'
import Layout1 from '@/layouts/Layout1'
import SignUpForm from '@/components/auth/SignUpForm'
import LoginForm from '@/components/auth/LoginForm'
import ForgotPasswordForm from '@/components/auth/ForgotPasswordForm'
import ResetPasswordForm from '@/components/auth/ResetPasswordForm'
import AuthBg from '@/assets/images/auth/auth-bg.png'
import AuthCleanBg from '@/assets/images/auth/auth-clean-bg.png'
import { Container } from './styles'

const AuthPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  let [searchParams] = useSearchParams()

  const redirectTo = searchParams.get('redirect_to')

  const { isAuthenticated } = useSelector((state) => state.users)

  const [formMode, setFormMode] = useState(null)

  useEffect(() => {
    if (redirectTo && isAuthenticated) {
      window.location.replace(redirectTo)
      return
    }

    if (isAuthenticated) {
      return navigate(`/`, { replace: true })
    }
  }, [isAuthenticated, redirectTo]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location?.pathname === '/register') setFormMode('signup')
    if (location?.pathname === '/login') setFormMode('login')
    if (location?.pathname === '/forgot-password') setFormMode('forgotPassword')
    if (location?.pathname === '/reset-password') setFormMode('resetPassword')
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout1>
      <Container>
        <div className="left-content" style={{ backgroundImage: `url(${isDataWarsHostName ? AuthBg : AuthCleanBg})` }}>
          <div className="content">
            <h1 className="title">Upskill your team with Real Life projects.</h1>
          </div>
        </div>

        <div className="right-content">
          <div className="content">
            {formMode === 'signup' && <SignUpForm />}
            {formMode === 'login' && <LoginForm />}
            {formMode === 'forgotPassword' && <ForgotPasswordForm />}
            {formMode === 'resetPassword' && <ResetPasswordForm />}
          </div>
        </div>
      </Container>
    </Layout1>
  )
}

export default AuthPage
