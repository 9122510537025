import { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { MDXEditor, BoldItalicUnderlineToggles, toolbarPlugin } from '@mdxeditor/editor'
import { Tooltip, Dropdown, Modal } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import {
  PlayRegular,
  ArrowCounterclockwiseRegular,
  PauseRegular,
  CommentRegular,
  MoreVertical24Regular,
  Edit24Regular,
  Delete24Regular,
  CheckmarkCircle24Regular,
  DismissCircle24Regular,
} from '@fluentui/react-icons'
import { showToast } from '@/utils/toast/index'
import Button from '@/components/Button'
import HTMLBlock from '@/components/HTMLBlock'
import MemberAvatar from '@/components/MemberAvatar'
import { updateComment, deleteComment } from '@/store/playgrounds/actions'
import { Container } from './styles'

const PlaygroundEvent = ({ user, playgroundCopyId, event }) => {
  const dispatch = useDispatch()

  const { userProfile } = useSelector((state) => state.users)
  const { isLoading } = useSelector((state) => state.playgrounds)

  const mdxEditorRef = useRef(null)
  const [isEdit, setIsEdit] = useState(false)

  const onCommentUpdated = () => {
    showToast('Comment updated successfully')
    setIsEdit(false)
  }

  const handleUpdateComment = () => {
    if (!mdxEditorRef.current?.getMarkdown()) {
      showToast("Your comment can't be empty!", 'error')
      return
    }

    dispatch(
      updateComment(
        playgroundCopyId,
        event?.id,
        { content_md: mdxEditorRef.current?.getMarkdown(), user_id: user?.id },
        onCommentUpdated,
      ),
    )
  }

  const handleDeleteComment = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this comment?',
      content: "This action can't be undone.",
      icon: <ExclamationCircleFilled />,
      okText: 'Yes, delete comment',
      cancelText: 'Cancel',
      onOk: () =>
        dispatch(
          deleteComment(playgroundCopyId, event?.id, { user_id: user?.id }, () =>
            showToast('Comment deleted successfully'),
          ),
        ),
      okButtonProps: {
        danger: true,
        type: 'primary',
        loading: isLoading,
      },
    })
  }

  const commentMenuItems = [
    {
      key: 'edit',
      label: (
        <a onClick={() => setIsEdit(true)} className="menu-item">
          <Edit24Regular /> Edit
        </a>
      ),
    },
    {
      key: 'delete',
      label: (
        <a onClick={handleDeleteComment} className="menu-item">
          <Delete24Regular /> Delete
        </a>
      ),
      danger: true,
    },
  ]

  const getEventContent = (event) => {
    const isSameUser = userProfile && userProfile?.username === event?.user?.username

    const eventData = {
      'playgrounds.playground.create': (
        <div className="event-content">
          <div className="icon-container">
            <PlayRegular className="icon" />
          </div>

          <div className="event-body">
            <div className="event-summary">
              <MemberAvatar
                lastName={user?.last_name}
                firstName={user?.first_name}
                showName
                avatarUrl={user?.avatar_url}
                externalId={user?.external_id}
              />

              <span className="text">started project — {dayjs(event?.datetime).from()}</span>
            </div>
          </div>
        </div>
      ),
      'playgrounds.playground.submit': (
        <div className="event-content">
          <div className="icon-container success">
            <span className="icon emoji">🎉</span>
          </div>

          <div className="event-body">
            <div className="event-summary">
              <MemberAvatar
                lastName={user?.last_name}
                firstName={user?.first_name}
                showName
                avatarUrl={user?.avatar_url}
                externalId={user?.external_id}
              />

              <span className="text">submitted project — {dayjs(event?.datetime).from()}</span>
            </div>
          </div>
        </div>
      ),
      'content.comment.create': (
        <div className="event-content">
          <div className="icon-container">
            <CommentRegular className="icon" />
          </div>

          <div className="event-body">
            <div className="event-summary">
              <MemberAvatar
                lastName={event?.user?.last_name}
                firstName={event?.user?.first_name}
                showName
                avatarUrl={event?.user?.avatar_url}
                externalId={event?.user?.external_id}
              />

              <span className="text">added a comment — {dayjs(event?.datetime).from()}</span>
            </div>

            <div className="comment-content-container">
              {isEdit ? (
                <>
                  <MDXEditor
                    ref={mdxEditorRef}
                    contentEditableClassName="editor-content"
                    markdown={event?.content_md}
                    placeholder="Write a comment..."
                    plugins={[
                      toolbarPlugin({
                        toolbarContents: () => <BoldItalicUnderlineToggles />,
                      }),
                    ]}
                  />

                  <div className="comment-actions">
                    <Tooltip title="Update">
                      <Button
                        type="text"
                        icon={<CheckmarkCircle24Regular className="icon" />}
                        onClick={handleUpdateComment}
                        disabled={isLoading}
                        loading={isLoading}
                      />
                    </Tooltip>

                    {!isLoading && (
                      <Tooltip title="Cancel">
                        <Button
                          type="text"
                          icon={<DismissCircle24Regular className="icon" />}
                          onClick={() => setIsEdit(false)}
                        />
                      </Tooltip>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <HTMLBlock content={event?.content_html} className="comment-content" />

                  {isSameUser && (
                    <Dropdown
                      overlayClassName={'playground-comments-menu'}
                      trigger={['click']}
                      menu={{
                        items: commentMenuItems,
                      }}
                      disabled={isLoading}
                    >
                      <Button
                        className="more-button"
                        type="text"
                        icon={<MoreVertical24Regular className="icon" />}
                        loading={isLoading}
                      />
                    </Dropdown>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ),
      'labs.session.allocate': (
        <div className="event-content">
          <div className="icon-container">
            <ArrowCounterclockwiseRegular className="icon" />
          </div>

          <div className="event-body">
            <div className="event-summary">
              <span className="text">System started lab — {dayjs(event?.datetime).from()}</span>
            </div>
          </div>
        </div>
      ),
      'labs.session.deallocate': (
        <div className="event-content">
          <div className="icon-container">
            <PauseRegular className="icon" />
          </div>

          <div className="event-body">
            <div className="event-summary">
              <span className="text">System stopped lab — {dayjs(event?.datetime).from()}</span>
            </div>
          </div>
        </div>
      ),
    }

    return eventData?.[event?.event_name] || <></>
  }

  const eventContent = getEventContent(event)

  return (
    <Container className="playground-event">
      {eventContent}

      <div className="divider" />
    </Container>
  )
}

export default PlaygroundEvent
