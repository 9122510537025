import {
  SET_ACCOUNTS_LOADING,
  SET_ACCOUNT_LOADING,
  SET_ACCOUNT_TEAMS_LOADING,
  SET_MODULE_ATTEMPTS_LOADING,
  SET_PLAYGROUND_VERSIONS_LOADING,
  SET_ACCOUNTS_LICENSE_LOADING,
  ACCOUNT_ACCOUNTS_DATA_UPDATED,
  ACCOUNT_UPDATED,
  ACCOUNT_ALL_USERS_UPDATED,
  ACCOUNT_USERS_DATA_UPDATED,
  ACCOUNT_COLLECTIONS_UPDATED,
  ACCOUNT_TEAMS_UPDATED,
  ACCOUNT_TEAM_UPDATED,
  ANALYTICS_LEADERBOARD_UPDATED,
  PROJECTS_ANALYTICS_UPDATED,
  PLAYGROUNDS_ANALYTICS_UPDATED,
  ATTEMPTS_ANALYTICS_UPDATED,
  MODULE_ATTEMPT_UPDATED,
  VERSIONS_ANALYTICS_UPDATED,
  PLAYGROUND_VERSION_UPDATED,
  ACTIVITY_ANALYTICS_UPDATED,
  ACCOUNT_FILTERED_TEAM_UPDATED,
  ACCOUNT_SELECTED_USERS_UPDATED,
  ACCOUNT_USER_SEARCH_TEXT_UPDATED,
  SET_WELCOME_MODAL_OPEN,
  SET_EDIT_ACCOUNT_MODAL_OPEN,
  SET_TEAM_MODAL_OPEN,
  SET_ADD_TO_TEAM_MODAL_OPEN,
  SET_PROJECT_ANALYTICS_MODAL_OPEN,
  SET_PLAYGROUND_ANALYTICS_MODAL_OPEN,
  SET_MEMBERS_TAB,
  SET_CONTENT_TAB,
  SET_ANALYTICS_TAB,
  SET_ACCOUNT_ERROR,
  SET_ACCOUNTS_ERROR,
  RESET_ACCOUNTS_STATE,
  // Invites
  SET_ACCOUNTS_INVITE_LOADING,
  ACCOUNT_INVITE_LINK_UPDATED,
  ACCOUNT_USER_INVITES_UPDATED,
  ACCOUNT_INVITE_EMAILS_UPDATED,
  ACCOUNT_INVITE_SETTINGS_UPDATED,
  SET_INVITE_MODAL_OPEN,
} from '@/store/accounts'
import { USER_PROFILE_UPDATED } from '@/store/users'
import {
  getAccounts,
  getAccount,
  postAccount,
  patchAccount,
  postAccountImage,
  getAccountUsers,
  postRemoveAccountUser,
  getAccountCollections,
  getAccountTeams,
  postAccountTeam,
  patchAccountTeam,
  deleteAccountTeam,
  postAddTeamUser,
  postRemoveTeamUser,
  getAccountAnalyticsLeaderboard,
  getAccountProjectsAnalytics,
  getAccountPlaygroundsAnalytics,
  getAccountAttempsAnalytics,
  getAccountVersionsAnalytics,
  getAccountActivityAnalytics,
  postAccountAddLicense,
  postAccountRemoveLicense,
  // Invites
  getAccountUserInvites,
  postAccountInviteLink,
  postAccountUserInvites,
  patchAccountInviteLink,
  patchAccountUserInvite,
  postAccountValidateEmails,
  postAccountUserInviteResend,
} from '@/services/accounts'
import { showToast } from '@/utils/toast/index'

const fetchAccounts = (params) => async (dispatch) => {
  try {
    dispatch(SET_ACCOUNTS_LOADING(true))

    const data = await getAccounts(params)

    dispatch(ACCOUNT_ACCOUNTS_DATA_UPDATED(data))
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNTS_LOADING(false))
  }
}

const fetchAccount = (accountId) => async (dispatch) => {
  try {
    dispatch(SET_ACCOUNTS_LOADING(true))

    const data = await getAccount(accountId)

    dispatch(ACCOUNT_UPDATED(data))
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNT_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNTS_LOADING(false))
  }
}

const createAccount = (data, callback) => async (dispatch, getState) => {
  try {
    dispatch(SET_ACCOUNTS_LOADING(true))

    const { userProfile } = getState().users

    const newAccount = await postAccount(data)
    const auxAccount = { id: newAccount?.id, name: newAccount?.name, role: 'owner' }

    const auxUserProfile = {
      ...userProfile,
      accounts: [auxAccount, ...userProfile.accounts],
    }

    dispatch(USER_PROFILE_UPDATED(auxUserProfile))

    if (callback) {
      callback(newAccount)
    }

    showToast('Welcome to your new dashboard')
  } catch (error) {
    const { message } = error
    showToast(error.message, 'error')
    dispatch(SET_ACCOUNT_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNTS_LOADING(false))
  }
}

const updateAccount = (accountId, data, callback) => async (dispatch, getState) => {
  try {
    dispatch(SET_ACCOUNT_LOADING(true))

    const { accountsData, currentAccount } = getState().accounts

    const response = await patchAccount(accountId, data)
    const auxAccount = { ...currentAccount, name: response?.name }
    const auxAccountsData = {
      ...accountsData,
      results: accountsData?.results?.map((account) => {
        if (account.id === accountId) {
          const auxAccount = { ...account, name: response?.name }
          return auxAccount
        }
        return account
      }),
    }

    dispatch(ACCOUNT_UPDATED(auxAccount))
    dispatch(ACCOUNT_ACCOUNTS_DATA_UPDATED(auxAccountsData))

    if (callback) {
      callback()
    }
  } catch (error) {
    const { message } = error
    showToast(message, 'error')
  } finally {
    dispatch(SET_ACCOUNT_LOADING(false))
  }
}

const updateAccountImage = (accountId, file, callback) => async (dispatch, getState) => {
  try {
    dispatch(SET_ACCOUNT_LOADING(true))

    const { accountsData, currentAccount } = getState().accounts

    const response = await postAccountImage(accountId, file)
    const auxAccount = { ...currentAccount, image_url: response?.url }
    const auxAccountsData = {
      ...accountsData,
      results: accountsData?.results?.map((account) => {
        if (account.id === accountId) {
          const auxAccount = { ...account, image_url: response?.url }
          return auxAccount
        }
        return account
      }),
    }

    dispatch(ACCOUNT_UPDATED(auxAccount))
    dispatch(ACCOUNT_ACCOUNTS_DATA_UPDATED(auxAccountsData))

    showToast('Your account image was updated.')

    if (callback) {
      callback()
    }
  } catch (error) {
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_ACCOUNT_LOADING(false))
  }
}

const fetchAccountUsers = (accountId, params) => async (dispatch) => {
  try {
    dispatch(SET_ACCOUNTS_LOADING(true))

    const data = await getAccountUsers(accountId, params)

    if (params?.['page_size'] === 'None') {
      dispatch(ACCOUNT_ALL_USERS_UPDATED(data))
    } else {
      dispatch(ACCOUNT_USERS_DATA_UPDATED(data))
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNTS_LOADING(false))
  }
}

const removeAccountUsers = (accountId, data) => async (dispatch, getState) => {
  try {
    dispatch(SET_ACCOUNTS_LOADING(true))

    const { currentAccount, usersData } = getState().accounts

    await postRemoveAccountUser(accountId, data)

    let auxUsersData = {
      ...usersData,
      results: usersData?.results?.filter((u) => !data?.user_ids?.includes(u?.user?.id)),
    }

    const assignedUserLicenses =
      usersData?.results?.filter((u) => data?.user_ids?.includes(u?.user?.id) && u?.has_license)?.length || 0

    let auxAccount = {
      ...currentAccount,
      licenses_summary: {
        ...currentAccount?.licenses_summary,
        assigned: currentAccount?.licenses_summary?.assigned - assignedUserLicenses,
        available: currentAccount?.licenses_summary?.available + assignedUserLicenses,
      },
    }

    dispatch(ACCOUNT_USERS_DATA_UPDATED(auxUsersData))
    dispatch(ACCOUNT_UPDATED(auxAccount))
    showToast('Member removed successfully')
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNTS_LOADING(false))
  }
}

const fetchAccountCollections = (accountId, params) => async (dispatch) => {
  try {
    dispatch(SET_ACCOUNTS_LOADING(true))

    const data = await getAccountCollections(accountId, params)

    dispatch(ACCOUNT_COLLECTIONS_UPDATED(data))
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNTS_LOADING(false))
  }
}

const fetchAccountTeams = (accountId, params) => async (dispatch) => {
  try {
    dispatch(SET_ACCOUNT_TEAMS_LOADING(true))

    const data = await getAccountTeams(accountId, params)

    dispatch(ACCOUNT_TEAMS_UPDATED(data))
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNT_TEAMS_LOADING(false))
  }
}

const createAccountTeam = (accountId, team, cb) => async (dispatch, getState) => {
  try {
    dispatch(SET_ACCOUNT_TEAMS_LOADING(true))

    const { teamsData } = getState().accounts

    const resData = await postAccountTeam(accountId, team)

    let auxTeam = { ...resData, users: [] }
    let auxTeams = {
      ...teamsData,
      count: teamsData.count + 1,
      results: [...teamsData.results, auxTeam]?.sort((a, b) => (a.name > b.name ? 1 : -1)),
    }

    dispatch(ACCOUNT_TEAMS_UPDATED(auxTeams))
    showToast('Team created successfully')

    if (cb) {
      cb({ ...auxTeam, isJustCreated: true })
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNT_TEAMS_LOADING(false))
  }
}

const updateAccountTeam = (accountId, teamId, team, cb) => async (dispatch, getState) => {
  try {
    dispatch(SET_ACCOUNT_TEAMS_LOADING(true))

    const { teamsData } = getState().accounts

    const resData = await patchAccountTeam(accountId, teamId, team)

    let auxTeams = {
      ...teamsData,
      count: teamsData.count + 1,
      results: teamsData.results?.map((t) => {
        if (t?.id === teamId) return { ...t, ...resData }
        return t
      }),
    }

    dispatch(ACCOUNT_TEAMS_UPDATED(auxTeams))
    showToast('Team updated successfully')

    if (cb) {
      cb()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNT_TEAMS_LOADING(false))
  }
}

const removeAccountTeam = (accountId, teamId, cb) => async (dispatch, getState) => {
  try {
    dispatch(SET_ACCOUNT_TEAMS_LOADING(true))

    const { teamsData } = getState().accounts

    const resData = await deleteAccountTeam(accountId, teamId)

    let auxTeams = {
      ...teamsData,
      count: teamsData.count - 1,
    }
    auxTeams.results = auxTeams?.results?.filter((t) => t?.id !== teamId)

    dispatch(ACCOUNT_TEAMS_UPDATED(auxTeams))
    showToast('Team deleted successfully')

    if (cb) {
      cb(resData)
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNT_TEAMS_LOADING(false))
  }
}

const addTeamUsers = (accountId, teamId, data, cb) => async (dispatch, getState) => {
  try {
    dispatch(SET_ACCOUNTS_LOADING(true))

    const { allUsers, usersData, teamsData } = getState().accounts

    await postAddTeamUser(accountId, teamId, data)

    const newMembers = allUsers
      ?.map((userData) => {
        const user = userData?.user
        if (data?.user_ids?.includes(user?.id)) {
          return user
        }
      })
      ?.filter((u) => u)

    const targetTeam = teamsData?.results?.find((t) => t.id === teamId)
    let auxTeam = {
      ...targetTeam,
      users: [...targetTeam.users, ...newMembers],
      total_users: targetTeam?.users?.length,
    }

    let auxTeams = {
      ...teamsData,
      results: teamsData?.results?.map((t) => {
        if (t.id === teamId) return auxTeam
        return t
      }),
    }

    let auxUsersData = {
      ...usersData,
      results: usersData?.results?.map((u) => {
        if (data?.user_ids?.includes(u?.user?.id)) {
          return {
            ...u,
            teams: [...u.teams, teamsData?.results?.find((t) => t.id === teamId)],
          }
        }

        return u
      }),
    }

    dispatch(ACCOUNT_TEAMS_UPDATED(auxTeams))
    dispatch(ACCOUNT_USERS_DATA_UPDATED(auxUsersData))
    showToast('Members updated successfully')

    if (cb) {
      cb()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNTS_LOADING(false))
  }
}

const removeTeamUsers = (accountId, teamId, data, cb) => async (dispatch, getState) => {
  try {
    dispatch(SET_ACCOUNTS_LOADING(true))

    const { usersData, teamsData } = getState().accounts

    await postRemoveTeamUser(accountId, teamId, data)

    const targetTeam = teamsData?.results?.find((t) => t.id === teamId)
    let auxTeam = {
      ...targetTeam,
      users: [...targetTeam.users.filter((u) => !data?.user_ids?.includes(u.id))],
    }

    let auxTeams = {
      ...teamsData,
      results: teamsData?.results?.map((t) => {
        if (t.id === teamId) return auxTeam
        return t
      }),
    }

    let auxUsersData = {
      ...usersData,
      results: usersData?.results?.map((u) => {
        if (data?.user_ids?.includes(u?.user?.id)) {
          return {
            ...u,
            teams: [...u.teams.filter((t) => t.id !== teamId)],
          }
        }

        return u
      }),
    }

    dispatch(ACCOUNT_TEAMS_UPDATED(auxTeams))
    dispatch(ACCOUNT_USERS_DATA_UPDATED(auxUsersData))
    showToast('Members updated successfully')

    if (cb) {
      cb()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNTS_LOADING(false))
  }
}

const setAccountTeam = (team) => (dispatch) => {
  dispatch(ACCOUNT_TEAM_UPDATED(team))
}

const fetchAnalyticsLeaderboard = (accountId, params) => async (dispatch) => {
  try {
    dispatch(SET_ACCOUNTS_LOADING(true))

    const data = await getAccountAnalyticsLeaderboard(accountId, params)

    dispatch(ANALYTICS_LEADERBOARD_UPDATED(data))
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNTS_LOADING(false))
  }
}

const fetchProjectsAnalytics = (accountId, params) => async (dispatch) => {
  try {
    dispatch(SET_ACCOUNTS_LOADING(true))

    const data = await getAccountProjectsAnalytics(accountId, params)

    dispatch(PROJECTS_ANALYTICS_UPDATED(data))
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNTS_LOADING(false))
  }
}

const fetchPlaygroundsAnalytics = (accountId, params) => async (dispatch) => {
  try {
    dispatch(SET_ACCOUNTS_LOADING(true))

    const data = await getAccountPlaygroundsAnalytics(accountId, params)

    dispatch(PLAYGROUNDS_ANALYTICS_UPDATED(data))
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNTS_LOADING(false))
  }
}

const fetchAttemptsAnalytics = (accountId, params) => async (dispatch) => {
  try {
    dispatch(SET_MODULE_ATTEMPTS_LOADING(true))

    const data = await getAccountAttempsAnalytics(accountId, params)

    dispatch(ATTEMPTS_ANALYTICS_UPDATED(data))
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_MODULE_ATTEMPTS_LOADING(false))
  }
}

const setModuleAttempt = (attemptId) => (dispatch) => {
  dispatch(MODULE_ATTEMPT_UPDATED(attemptId))
}

const fetchVersionsAnalytics = (accountId, params) => async (dispatch) => {
  try {
    dispatch(SET_PLAYGROUND_VERSIONS_LOADING(true))

    const data = await getAccountVersionsAnalytics(accountId, params)

    dispatch(VERSIONS_ANALYTICS_UPDATED(data))
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_PLAYGROUND_VERSIONS_LOADING(false))
  }
}

const setVersionsAnalytics = (data) => async (dispatch) => {
  dispatch(VERSIONS_ANALYTICS_UPDATED(data))
}

const setPlaygroundVersion = (versionId) => (dispatch) => {
  dispatch(PLAYGROUND_VERSION_UPDATED(versionId))
}

const fetchActivityAnalytics = (accountId, params) => async (dispatch) => {
  try {
    dispatch(SET_ACCOUNTS_LOADING(true))

    const data = await getAccountActivityAnalytics(accountId, params)

    dispatch(ACTIVITY_ANALYTICS_UPDATED(data))
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNTS_LOADING(false))
  }
}

const addAccountLicense = (accountId, data) => async (dispatch, getState) => {
  try {
    dispatch(SET_ACCOUNTS_LICENSE_LOADING(true))

    const { currentAccount, usersData } = getState().accounts

    await postAccountAddLicense(accountId, data)

    let auxUsersData = {
      ...usersData,
      results: usersData?.results?.map((u) => ({
        ...u,
        has_license: u?.user?.id === data?.user_id ? true : u.has_license,
      })),
    }

    let auxAccount = {
      ...currentAccount,
      licenses_summary: {
        ...currentAccount?.licenses_summary,
        assigned: currentAccount?.licenses_summary?.assigned + 1,
        available: currentAccount?.licenses_summary?.available - 1,
      },
    }

    dispatch(ACCOUNT_USERS_DATA_UPDATED(auxUsersData))
    dispatch(ACCOUNT_UPDATED(auxAccount))

    showToast('License assigned successfully')
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNTS_LICENSE_LOADING(false))
  }
}

const removeAccountLicense = (accountId, data) => async (dispatch, getState) => {
  try {
    dispatch(SET_ACCOUNTS_LICENSE_LOADING(true))

    const { currentAccount, usersData } = getState().accounts

    await postAccountRemoveLicense(accountId, data)

    let auxUsersData = {
      ...usersData,
      results: usersData?.results?.map((u) => ({
        ...u,
        has_license: u?.user?.id === data?.user_id ? false : u.has_license,
      })),
    }

    let auxAccount = {
      ...currentAccount,
      licenses_summary: {
        ...currentAccount?.licenses_summary,
        assigned: currentAccount?.licenses_summary?.assigned - 1,
        available: currentAccount?.licenses_summary?.available + 1,
      },
    }

    dispatch(ACCOUNT_USERS_DATA_UPDATED(auxUsersData))
    dispatch(ACCOUNT_UPDATED(auxAccount))

    showToast('License removed successfully')
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNTS_LICENSE_LOADING(false))
  }
}

const setFilteredTeam = (teamId) => (dispatch) => {
  dispatch(ACCOUNT_FILTERED_TEAM_UPDATED(teamId))
}

const setSelectedUsers = (userIds) => (dispatch) => {
  dispatch(ACCOUNT_SELECTED_USERS_UPDATED(userIds))
}

const setUserSearchText = (text) => (dispatch) => {
  dispatch(ACCOUNT_USER_SEARCH_TEXT_UPDATED(text))
}

const setWelcomeModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_WELCOME_MODAL_OPEN(isOpen))
}

const setEditAccountModalOpen = (isOpen, account) => (dispatch) => {
  dispatch(SET_EDIT_ACCOUNT_MODAL_OPEN(isOpen))

  if (account) {
    dispatch(ACCOUNT_UPDATED(account))
  }
}

const setTeamModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_TEAM_MODAL_OPEN(isOpen))
}

const setAddToTeamModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_ADD_TO_TEAM_MODAL_OPEN(isOpen))
}

const setProjectAnalyticsModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_PROJECT_ANALYTICS_MODAL_OPEN(isOpen))
}

const setPlaygroundAnalyticsModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_PLAYGROUND_ANALYTICS_MODAL_OPEN(isOpen))
}

const setMembersTab = (tab) => (dispatch) => {
  dispatch(SET_MEMBERS_TAB(tab))
}

const setContentTab = (tab) => (dispatch) => {
  dispatch(SET_CONTENT_TAB(tab))
}

const setAnalyticsTab = (tab) => (dispatch) => {
  dispatch(SET_ANALYTICS_TAB(tab))
}

const resetAccountsState = () => (dispatch) => {
  dispatch(RESET_ACCOUNTS_STATE())
}

// Invites
const fetchAccountUserInvites = (accountId, queryParams) => async (dispatch) => {
  try {
    dispatch(SET_ACCOUNTS_INVITE_LOADING(true))

    const data = await getAccountUserInvites(accountId, queryParams)

    dispatch(ACCOUNT_USER_INVITES_UPDATED(data))
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNTS_INVITE_LOADING(false))
  }
}

const createAccountInviteLink = (accountId, data) => async (dispatch) => {
  try {
    dispatch(SET_ACCOUNTS_INVITE_LOADING(true))

    const inviteLink = await postAccountInviteLink(accountId, data)

    dispatch(ACCOUNT_INVITE_LINK_UPDATED(inviteLink))
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
    showToast(message, 'error')
  } finally {
    dispatch(SET_ACCOUNTS_INVITE_LOADING(false))
  }
}

const createAccountUserInvites = (accountId, data, callback) => async (dispatch, getState) => {
  try {
    dispatch(SET_ACCOUNTS_INVITE_LOADING(true))

    const { userInvites } = getState().accounts

    const createdUserInvites = await postAccountUserInvites(accountId, data)

    if (userInvites?.results) {
      let auxUserInvites = {
        ...userInvites,
        results: [...userInvites.results, ...createdUserInvites],
        count: userInvites.count + createdUserInvites.length,
      }

      dispatch(ACCOUNT_USER_INVITES_UPDATED(auxUserInvites))
    }

    if (callback) {
      callback()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
    showToast(message, 'error')
  } finally {
    dispatch(SET_ACCOUNTS_INVITE_LOADING(false))
  }
}

const updateAccountInviteLink = (accountId, inviteLinkId, data) => async (dispatch) => {
  try {
    dispatch(SET_ACCOUNTS_INVITE_LOADING(true))

    const inviteLink = await patchAccountInviteLink(accountId, inviteLinkId, data)

    dispatch(ACCOUNT_INVITE_LINK_UPDATED(inviteLink))
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
    showToast(message, 'error')
  } finally {
    dispatch(SET_ACCOUNTS_INVITE_LOADING(false))
  }
}

const updateAccountUserInvite = (accountId, inviteId, data) => async (dispatch, getState) => {
  try {
    dispatch(SET_ACCOUNTS_INVITE_LOADING(true))

    const { userInvites } = getState().accounts

    const updatedUserInvite = await patchAccountUserInvite(accountId, inviteId, data)

    let auxUserInvites = {
      ...userInvites,
      results: userInvites?.results?.map((invite) => (invite.id === inviteId ? updatedUserInvite : invite)),
    }

    if (data.status === 'revoked') {
      auxUserInvites.results = auxUserInvites.results.filter((invite) => invite.id !== inviteId)
      auxUserInvites.count -= 1

      showToast('Invite revoked successfully')
    }

    dispatch(ACCOUNT_USER_INVITES_UPDATED(auxUserInvites))
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
    showToast(message, 'error')
  } finally {
    dispatch(SET_ACCOUNTS_INVITE_LOADING(false))
  }
}

const validateInviteEmails = (data) => async (dispatch, getState) => {
  try {
    dispatch(SET_ACCOUNTS_INVITE_LOADING(true))

    if (!data?.emails?.length) {
      dispatch(ACCOUNT_INVITE_EMAILS_UPDATED([]))
      return
    }

    const emailsData = await postAccountValidateEmails(data)

    const { inviteSettings, inviteEmails } = getState().accounts

    const emailsDataAux = emailsData?.map((newEmailData) => {
      const oldEmailData = inviteEmails?.find((emailData) => emailData?.email === newEmailData?.email)
      const defaultValues = {
        teams: inviteSettings.teamId ? [inviteSettings.teamId] : [],
        assign_license: inviteSettings?.assignLicense ? true : false,
      }

      return {
        ...(oldEmailData || defaultValues),
        ...newEmailData,
      }
    })

    dispatch(ACCOUNT_INVITE_EMAILS_UPDATED(emailsDataAux))
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
  } finally {
    dispatch(SET_ACCOUNTS_INVITE_LOADING(false))
  }
}

const resendAccountUserInvite = (accountId, inviteId) => async (dispatch) => {
  try {
    dispatch(SET_ACCOUNTS_INVITE_LOADING(true))

    await postAccountUserInviteResend(accountId, inviteId)

    showToast('Invite resent successfully')
  } catch (error) {
    const { message } = error
    dispatch(SET_ACCOUNTS_ERROR(message))
    showToast(message, 'error')
  } finally {
    dispatch(SET_ACCOUNTS_INVITE_LOADING(false))
  }
}

const setInviteEmails = (email, fieldToUpdate, newValue) => (dispatch, getState) => {
  const { inviteEmails } = getState().accounts

  const auxEmailsData = inviteEmails?.map((emailData) => {
    const shouldUpdate = emailData?.email === email
    if (!shouldUpdate) return emailData

    return {
      ...emailData,
      ...(fieldToUpdate === 'teamId' ? { teams: newValue ? [newValue] : [] } : {}),
      ...(fieldToUpdate === 'assignLicense' ? { assign_license: newValue } : {}),
    }
  })

  dispatch(ACCOUNT_INVITE_EMAILS_UPDATED(auxEmailsData))
}

const setInviteSettings = (fieldToUpdate, newValue) => (dispatch, getState) => {
  const { inviteSettings, inviteEmails } = getState().accounts

  const auxSettings = {
    ...inviteSettings,
    [fieldToUpdate]: newValue,
  }
  dispatch(ACCOUNT_INVITE_SETTINGS_UPDATED(auxSettings))

  if (!inviteEmails?.length) return

  const auxInviteEmails = inviteEmails?.map((emailData) => ({
    ...emailData,
    ...(fieldToUpdate === 'teamId' ? { teams: newValue ? [newValue] : [] } : {}),
    ...(fieldToUpdate === 'assignLicense' ? { assign_license: newValue } : {}),
  }))
  dispatch(ACCOUNT_INVITE_EMAILS_UPDATED(auxInviteEmails))
}

const setInviteModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_INVITE_MODAL_OPEN(isOpen))
}

export {
  fetchAccounts,
  fetchAccount,
  createAccount,
  updateAccount,
  updateAccountImage,
  fetchAccountUsers,
  removeAccountUsers,
  fetchAccountCollections,
  fetchAccountTeams,
  createAccountTeam,
  updateAccountTeam,
  removeAccountTeam,
  addTeamUsers,
  removeTeamUsers,
  setAccountTeam,
  fetchAnalyticsLeaderboard,
  fetchProjectsAnalytics,
  fetchPlaygroundsAnalytics,
  fetchAttemptsAnalytics,
  setModuleAttempt,
  fetchVersionsAnalytics,
  setVersionsAnalytics,
  setPlaygroundVersion,
  fetchActivityAnalytics,
  addAccountLicense,
  removeAccountLicense,
  setFilteredTeam,
  setSelectedUsers,
  setUserSearchText,
  setWelcomeModalOpen,
  setEditAccountModalOpen,
  setTeamModalOpen,
  setAddToTeamModalOpen,
  setProjectAnalyticsModalOpen,
  setPlaygroundAnalyticsModalOpen,
  setMembersTab,
  setContentTab,
  setAnalyticsTab,
  resetAccountsState,
  // Invites
  fetchAccountUserInvites,
  createAccountInviteLink,
  createAccountUserInvites,
  updateAccountInviteLink,
  updateAccountUserInvite,
  validateInviteEmails,
  resendAccountUserInvite,
  setInviteEmails,
  setInviteSettings,
  setInviteModalOpen,
}
