import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Table, Avatar, Tooltip } from 'antd'
import { EditOutlined, UserAddOutlined, LineChartOutlined, DeleteOutlined } from '@ant-design/icons'
import TeamItem from '@/pages/MainPage/components/TeamItem'
import Button from '@/components/Button'
import MemberAvatar from '@/components/MemberAvatar'
import { fetchAccountTeams, setAccountTeam, setFilteredTeam, setTeamModalOpen } from '@/store/accounts/actions'
import { Container } from './styles'

const TeamsTable = ({ size, hideActions, isPreview }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { themeConfig, pageSize: defaultPageSize } = useSelector((state) => state.app)
  const { currentAccount: account, teamsData, isTeamsLoading } = useSelector((state) => state.accounts)

  const inviteFulfillmentIsEnabled = account?.config?.fulfillment?.invite

  const [pageSize, setPageSize] = useState(defaultPageSize)

  const columns = [
    {
      title: 'Team',
      dataIndex: 'team',
      fixed: 'left',
    },
    {
      title: 'Members',
      dataIndex: 'members',
    },
    ...(hideActions
      ? []
      : [
          {
            title: 'Actions',
            dataIndex: 'actions',
            width: 60,
          },
        ]),
  ]

  const data = teamsData?.results?.slice(0, isPreview ? 2 : undefined)?.map((t) => ({
    key: t.id,
    team: <TeamItem team={t} size="small" />,
    members: t?.users?.length ? (
      <Avatar.Group
        className="members-container"
        max={{ count: 3, style: { color: themeConfig.colors.volcano5, backgroundColor: themeConfig.colors.volcano1 } }}
      >
        {t.users?.map((u) => (
          <MemberAvatar
            key={u?.id}
            lastName={u?.last_name}
            firstName={u?.first_name}
            avatarUrl={u?.avatar_url}
            externalId={u?.external_id}
            showTooltip
          />
        ))}
      </Avatar.Group>
    ) : (
      '—'
    ),
    actions: (
      <div className="team-actions">
        <Tooltip title="View Analytics">
          <Button
            icon={<LineChartOutlined />}
            onClick={() => {
              dispatch(setFilteredTeam(t?.id))
              navigate(`/accounts/${account?.id}/analytics`)
            }}
          />
        </Tooltip>

        <Tooltip title="Edit team">
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              dispatch(setAccountTeam(t))
              dispatch(setTeamModalOpen('edit'))
            }}
          />
        </Tooltip>

        {inviteFulfillmentIsEnabled && (
          <Tooltip title="Add members">
            <Button
              icon={<UserAddOutlined />}
              onClick={() => {
                dispatch(setAccountTeam(t))
                dispatch(setTeamModalOpen('invite'))
              }}
            />
          </Tooltip>
        )}

        <Tooltip title="Delete team">
          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              dispatch(setAccountTeam(t))
              dispatch(setTeamModalOpen('delete'))
            }}
          />
        </Tooltip>
      </div>
    ),
  }))

  const handleTableChange = (pagination) => {
    const { current: page, pageSize } = pagination

    setPageSize(pageSize)
    dispatch(fetchAccountTeams(account?.id, { page_size: pageSize, page }))
  }

  useEffect(() => {
    if (!account || teamsData) return

    dispatch(fetchAccountTeams(account?.id, { page_size: pageSize }))
  }, [account])

  return (
    <Container className="teams-table-container">
      <Table
        className="teams-table"
        columns={columns}
        dataSource={data}
        size={size}
        loading={!teamsData || isTeamsLoading}
        onChange={handleTableChange}
        pagination={
          !isPreview && {
            pageSize,
            total: teamsData?.count,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} teams`,
          }
        }
        scroll={{
          y: true,
          x: true,
        }}
      />
    </Container>
  )
}
export default TeamsTable
