import { useSelector } from 'react-redux'
import { Skeleton } from 'antd'
import { Pie } from '@ant-design/plots'
import { isDataWarsHostName } from '@/helpers/env'
import ProLabel from '@/components/ProLabel'
import { Container } from './styles'

const LicensesCard = () => {
  const { themeConfig } = useSelector((state) => state.app)
  const { currentAccount: account, isLoading: isAccountsLoading } = useSelector((state) => state.accounts)

  const licensesInfo = account?.licenses_summary

  const chartConfig = {
    data: [
      { type: 'Available Licenses', value: licensesInfo?.available || 0 },
      { type: 'Assigned Licenses', value: licensesInfo?.assigned || 0 },
    ],
    height: 240,
    margin: 0,
    angleField: 'value',
    colorField: 'type',
    startAngle: Math.PI,
    endAngle: Math.PI * 2,
    innerRadius: 0.6,
    legend: false,
    tooltip: false,
    scale: { color: { range: [themeConfig.colors.purple5, themeConfig.colors.cyan5] } },
  }

  return (
    <Container className="card licenses-card">
      {isAccountsLoading ? (
        <div className="loading-content">
          <Skeleton active paragraph={{ rows: 4 }} />
        </div>
      ) : (
        <div className="content">
          <div className="more-licenses-box">
            <p className="text">
              Need more licenses?
              <br />
              <span className="small">
                Contact us at{' '}
                <a href={`mailto:sales@${isDataWarsHostName ? 'datawars.io' : 'namespace.im'}?subject=Licenses`}>
                  sales@{isDataWarsHostName ? 'datawars.io' : 'namespace.im'}
                </a>
                .
              </span>
            </p>
          </div>

          <div className="licenses-info">
            <div className="title-container">
              {isDataWarsHostName && <ProLabel />}
              <p className="title">Licenses</p>
            </div>

            <div className="license-type-info">
              <h4 className="value">
                <span className="dot assigned" />
                {licensesInfo?.assigned}
              </h4>

              <p className="name">Assigned Licenses</p>
            </div>

            <div className="license-type-info">
              <h4 className="value">
                <span className="dot available" />

                <span>{licensesInfo?.available}</span>
              </h4>

              <p className="name">Available Licenses</p>
            </div>
          </div>

          <div className="chart-content">
            <span />

            {licensesInfo && (
              <div className="chart-box">
                <Pie {...chartConfig} />

                <div className="info">
                  <h3 className="title">{licensesInfo?.total}</h3>
                  <p className="text">Total licenses</p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Container>
  )
}

export default LicensesCard
