import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { InstantSearch, SearchBox, Configure, useInstantSearch } from 'react-instantsearch'
import { Spin, Collapse, Empty } from 'antd'
import { SearchOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { getAlgoliaSearchClient } from '@/utils/algolia'
import ModuleBox from '@/components/ModuleBox'
import PlaygroundBox from '@/components/PlaygroundBox'
import { fetchAccountCollections } from '@/store/accounts/actions'
import { fetchPlaygrounds, resetPlaygroundsState } from '@/store/playgrounds/actions'
import searchParameters from './searchParameters'
import { Container } from './styles'

const CollectionsContent = () => {
  const { results, indexUiState } = useInstantSearch()
  const searchText = indexUiState?.query

  const { collectionsData } = useSelector((state) => state.accounts)
  const { playgroundsData, isLoading: isPlaygroundsLoading } = useSelector((state) => state.playgrounds)

  const [hydratatedCollection, setHydratatedCollection] = useState([])

  const getModule = (id) => {
    const hit = results?.hits?.find((result) => result?.objectID === id)
    const module = {
      ...hit,
      id: hit?.objectID,
      tags: hit?.tags?.map((t) => t.name),
      type: 'module',
    }

    if (!hit) return null
    return module
  }

  const getPlayground = (id) => {
    const playground = playgroundsData?.results?.find((playground) => playground?.id === id)

    if (!playground) return null
    return { ...playground, type: 'playground' }
  }

  useEffect(() => {
    if (!collectionsData) return

    const auxCollectionData = collectionsData?.map((collection) => {
      const auxCollection = { ...collection }
      auxCollection.content = auxCollection?.content
        ?.map((level1) => {
          const auxLevel1 = { ...level1 }
          auxLevel1.content = auxLevel1?.content
            ?.map((level2) => {
              if (level2?.type === 'module') {
                return getModule(level2?.id)
              }

              if (level2?.type === 'playground') {
                return getPlayground(level2?.id)
              }

              return false
            })
            .filter(Boolean)

          return auxLevel1
        })
        .filter((level1) => level1?.content?.length)

      return auxCollection
    })

    setHydratatedCollection(auxCollectionData)
  }, [collectionsData, results, playgroundsData])

  if (!collectionsData || isPlaygroundsLoading)
    return (
      <div className="loading-container">
        <Spin size="large" />
        <p>Loading collections...</p>
      </div>
    )

  if (!collectionsData?.length) return <Empty className="no-content" description="No collections created yet." />

  return (
    <>
      <div className="header">
        <div className="search-box">
          <SearchBox placeholder="Search projects here..." />
          <SearchOutlined className="icon" />
        </div>
      </div>

      <div className="body">
        <div className="content">
          {hydratatedCollection?.map((collection, index) => {
            return (
              <Collapse
                key={`collection-${index}`}
                className="collections-container"
                ghost
                expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
                expandIconPosition="end"
                defaultActiveKey={['collection-0']}
                items={[
                  {
                    key: `collection-${index}`,
                    label: <h5 className="title">{collection?.name}</h5>,
                    className: 'collection-container',
                    children: (
                      <>
                        <div className="collection-content">
                          {collection?.content?.map((level1, index) => (
                            <Collapse
                              key={`section-${index}`}
                              className="section-container"
                              ghost
                              defaultActiveKey={collection?.content?.map((s, i) => `section-${i}`)}
                              items={[
                                {
                                  key: `section-${index}`,
                                  label: <p className="level-title">{level1?.name}</p>,
                                  children: level1?.content?.map((level2) => (
                                    <div key={level2?.id} className="content-container">
                                      {level2?.type === 'module' && <ModuleBox module={level2} size="small" />}
                                      {level2?.type === 'playground' && (
                                        <PlaygroundBox playground={level2} size="small" />
                                      )}
                                    </div>
                                  )),
                                },
                              ]}
                            />
                          ))}

                          {collection?.content?.length === 0 && (
                            <div className="no-results-box">
                              <h6 className="title">No projects found</h6>
                              <p className="text">
                                {searchText && (
                                  <span>
                                    “{searchText}” did not match any project.
                                    <br />
                                  </span>
                                )}
                                Please try again
                              </p>
                            </div>
                          )}
                        </div>
                      </>
                    ),
                  },
                ]}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

const CustomProjectsTab = () => {
  const dispatch = useDispatch()
  const { accountId } = useParams()

  const algoliaSearchClient = getAlgoliaSearchClient()

  const { collectionsData } = useSelector((state) => state.accounts)

  const accountModulesFilter =
    collectionsData
      ?.map((collection) => collection?.content)
      ?.flat()
      ?.map((level1) => level1?.content)
      ?.flat()
      ?.filter((level2) => level2.type === 'module')
      ?.map((level2) => `objectID:${level2?.id}`)
      ?.join(' OR ') || 'id:"NO"'

  const auxSearchParameters = {
    ...searchParameters,
    filters: `${accountModulesFilter}`,
  }

  useEffect(() => {
    if (!collectionsData) return

    const accountPlaygroundIds = collectionsData
      ?.map((collection) => collection?.content)
      ?.flat()
      ?.map((level1) => level1?.content)
      ?.flat()
      ?.filter((level2) => level2.type === 'playground')
      ?.map((level2) => level2?.id)
      ?.join(',')

    if (accountPlaygroundIds) {
      dispatch(fetchPlaygrounds({ account_id: accountId, is_student_copy: false, ids: accountPlaygroundIds }))
    }
  }, [collectionsData])

  useEffect(() => {
    dispatch(fetchAccountCollections(accountId))

    return () => {
      dispatch(resetPlaygroundsState())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className="collections-tab">
      <InstantSearch
        searchClient={algoliaSearchClient}
        indexName={import.meta.env.REACT_APP_ALGOLIA_MODULES_INDEX}
        // FIXME: be ready for next Algolia update
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Configure {...auxSearchParameters} />

        <CollectionsContent />
      </InstantSearch>
    </Container>
  )
}

export default CustomProjectsTab
