import { useDispatch, useSelector } from 'react-redux'
import { Collapse, Table, Switch, Select, Empty } from 'antd'
import { isDataWarsHostName } from '@/helpers/env'
import ProTooltip from '@/components/ProTooltip'
import TeamItem from '@/pages/MainPage/components/TeamItem'
import { setInviteEmails } from '@/store/accounts/actions'
import { Container } from './styles'

const InviteEmailsDetail = () => {
  const dispatch = useDispatch()

  const { currentAccount, teamsData, inviteEmails, isInviteLoading } = useSelector((state) => state.accounts)

  const hasAvailableLicenses = !!currentAccount?.licenses_summary?.available
  const validEmails = inviteEmails?.filter((email) => email.is_valid)
  const errorEmails = inviteEmails?.filter((email) => email.error)

  if (!validEmails?.length) {
    return (
      <Container className="invite-emails-detail-container">
        <div className="empty-content">
          <Empty description="You didn't add any email yet" />
        </div>
      </Container>
    )
  }

  const handleAssignLicense = (email, assignLicense) => {
    dispatch(setInviteEmails(email, 'assignLicense', assignLicense))
  }

  const handleAssignTeam = (email, teamId) => {
    dispatch(setInviteEmails(email, 'teamId', teamId))
  }

  return (
    <Container className="invite-emails-detail-container">
      <div className="content">
        <div className="emails-container">
          <Table
            size="middle"
            columns={[
              { title: 'Email', dataIndex: 'email', className: 'email-col' },
              { title: 'License', dataIndex: 'license', className: 'license-col' },
              ...(teamsData?.results?.length ? [{ title: 'Team', dataIndex: 'team', className: 'team-col' }] : []),
            ]}
            dataSource={validEmails?.map((emailData, index) => ({
              key: index,
              email: emailData?.email,
              license: (
                <ProTooltip
                  title={!hasAvailableLicenses && 'Need more licenses?'}
                  content={
                    <p className="text">
                      Contact us at{' '}
                      <a href={`mailto:sales@${isDataWarsHostName ? 'datawars.io' : 'namespace.im'}?subject=Licenses`}>
                        sales@{isDataWarsHostName ? 'datawars.io' : 'namespace.im'}
                      </a>
                      .
                    </p>
                  }
                >
                  <Switch
                    size="small"
                    checked={emailData?.assign_license}
                    onChange={(isChecked) => handleAssignLicense(emailData?.email, isChecked)}
                    disabled={!currentAccount?.licenses_summary?.available}
                  />
                </ProTooltip>
              ),
              team: (
                <Select
                  className="team-select"
                  dropdownStyle={{ width: 'initial' }}
                  variant="borderless"
                  value={emailData?.teams?.[0]}
                  placeholder="—"
                  notFoundContent="No teams found"
                  options={teamsData?.results?.map((t) => ({
                    value: t.id,
                    label: <TeamItem team={t} size="xsmall" />,
                  }))}
                  onChange={(value) => handleAssignTeam(emailData?.email, value)}
                  allowClear
                  virtual={false}
                />
              ),
            }))}
            pagination={false}
            loading={isInviteLoading}
          />
        </div>

        {!!errorEmails?.length && (
          <div className="errors-container">
            <Collapse
              ghost
              expandIconPosition="end"
              items={[
                {
                  key: '1',
                  label: `${errorEmails?.length} email${errorEmails?.length > 1 ? 's' : ''} had errors`,
                  children: (
                    <ul className="emails-list">
                      {errorEmails?.map((email) => (
                        <li key={email?.email} className="email">
                          <span className="highlight">{email?.email}</span>: {email?.error}
                        </li>
                      ))}
                    </ul>
                  ),
                },
              ]}
            />
          </div>
        )}
      </div>
    </Container>
  )
}

export default InviteEmailsDetail
