import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const getPlaygrounds = async (params) => {
  const route = 'playgrounds'
  return API.get(route, { params })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postPlayground = async (data) => {
  const route = 'playgrounds'
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const patchPlayground = async (playgroundId, data) => {
  const route = `playgrounds/${playgroundId}`
  return API.patch(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postPlaygroundPreview = async (playgroundId, data) => {
  const route = `playgrounds/${playgroundId}/previews`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postPlaygroundComment = async (playgroundId, data) => {
  const route = `playgrounds/${playgroundId}/comments`
  return API.post(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const patchPlaygroundComment = async (playgroundId, commentId, data) => {
  const route = `playgrounds/${playgroundId}/comments/${commentId}`
  return API.patch(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const deletePlaygroundComment = async (playgroundId, commentId) => {
  const route = `playgrounds/${playgroundId}/comments/${commentId}`
  return API.delete(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export {
  getPlaygrounds,
  postPlayground,
  patchPlayground,
  postPlaygroundPreview,
  postPlaygroundComment,
  patchPlaygroundComment,
  deletePlaygroundComment,
}
