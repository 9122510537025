import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Input, Segmented, Tooltip } from 'antd'
import { PeopleAdd24Regular, Copy24Regular, Send24Regular, CheckmarkCircle24Regular } from '@fluentui/react-icons'
import { getPlatformURL } from '@/helpers/env'
import { showToast } from '@/utils/toast'
import Button from '@/components/Button'
import InviteSettings from './components/InviteSettings'
import EmailsInput from './components/EmailsInput'
import InviteEmailsDetail from './components/InviteEmailsDetail'
import { createAccountInviteLink, createAccountUserInvites, setInviteModalOpen } from '@/store/accounts/actions'
import GoogleClassromIcon from '@/assets/images/icons/google-classroom.png'
import { Modal } from './styles'

const InviteModal = () => {
  const dispatch = useDispatch()

  const [inviteType, setInviteType] = useState('link')
  const [emails, setEmails] = useState([])

  const {
    currentAccount: account,
    currentInviteLink: inviteLink,
    inviteSettings,
    inviteEmails,
    isInviteLoading,
    isInviteModalOpen,
  } = useSelector((state) => state.accounts)

  const inviteLinkUrl = `${getPlatformURL(account?.slug)}invite/${inviteLink?.id?.split('-')?.[0]}`
  const googleClassroomURL = `https://classroom.google.com/share?url=${inviteLinkUrl}&title=${'Join our DataWars account!'}&body=${`Join our account at DataWars using the following link ${inviteLinkUrl}`}&itemtype=announcement`

  const isLinkInvite = inviteType === 'link'
  const isEmailInvite = inviteType === 'email'
  const validEmails = inviteEmails?.filter((email) => email.is_valid)

  const handleCreateInviteLink = async () => {
    await dispatch(createAccountInviteLink(account?.id))
  }

  const handleCreateUserInvites = async () => {
    const data = {
      custom_text_md: inviteSettings?.customText || '',
      invites: inviteEmails?.filter((emailData) => emailData.is_valid),
    }

    await dispatch(
      createAccountUserInvites(account?.id, data, () => {
        dispatch(setInviteModalOpen(false))
        showToast('Invites sent successfully')
      }),
    )
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(inviteLinkUrl)
    showToast('Link copied to clipboard')
  }

  useEffect(() => {
    if (!isInviteModalOpen) {
      setInviteType('link')
      return
    }

    handleCreateInviteLink()
  }, [isInviteModalOpen])

  return (
    <Modal
      className="invite-modal"
      open={!!isInviteModalOpen}
      onCancel={() => dispatch(setInviteModalOpen(false))}
      footer={null}
      inviteType={inviteType}
    >
      <div className="container">
        <div className="main-content">
          <div className="header">
            <div className="title-container">
              <PeopleAdd24Regular className="icon" />
              <h3 className="title">Invite users to join your account</h3>
            </div>

            <div className="invite-type-selector">
              <Segmented
                value={inviteType}
                options={[
                  { label: 'Email invitation', value: 'email' },
                  { label: 'Invite link', value: 'link' },
                ]}
                onChange={(value) => setInviteType(value)}
                disabled={isInviteLoading}
              />
            </div>
          </div>

          <div className="section">
            {isLinkInvite && (
              <Input.Search
                className="link-input"
                placeholder={isInviteLoading ? 'Generating invite link...' : inviteLinkUrl}
                size="large"
                readOnly
                onSearch={handleCopyToClipboard}
                loading={isInviteLoading}
                enterButton={
                  !isInviteLoading && (
                    <Tooltip title="Copy to clipboard" overlayClassName={'on-modal'}>
                      <Copy24Regular className="icon copy-icon" />
                    </Tooltip>
                  )
                }
              />
            )}

            {isEmailInvite && <EmailsInput emails={emails} setEmails={setEmails} />}
          </div>

          <InviteSettings inviteType={inviteType} bottomText={isLinkInvite && 'This invite link expires in 30 days.'} />
        </div>

        {isEmailInvite && (
          <div className="emails-content">
            <InviteEmailsDetail />{' '}
          </div>
        )}
      </div>

      <div className="actions">
        <div className="content">
          {isLinkInvite && (
            <>
              <Link to={googleClassroomURL} target="_blank">
                <Button
                  className="share-google-classroom-btn"
                  type="default"
                  icon={<img className="icon" src={GoogleClassromIcon} alt="Add to Google Classroom" />}
                  disabled={isInviteLoading}
                >
                  Add to Google Classroom
                </Button>
              </Link>

              <Button className="button" type="secondary" onClick={handleCopyToClipboard} disabled={isInviteLoading}>
                <Copy24Regular className="icon" /> Copy invite link
              </Button>

              <Button className="button" onClick={() => dispatch(setInviteModalOpen(false))} loading={isInviteLoading}>
                <CheckmarkCircle24Regular className="icon" /> Done
              </Button>
            </>
          )}

          {isEmailInvite && (
            <Button
              className="button"
              type="secondary"
              onClick={handleCreateUserInvites}
              loading={isInviteLoading}
              disabled={!validEmails?.length}
            >
              <Send24Regular className="icon" /> Send invites
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default InviteModal
