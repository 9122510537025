import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { Input, Select, Switch } from 'antd'
import { isDataWarsHostName } from '@/helpers/env'
import TeamItem from '@/pages/MainPage/components/TeamItem'
import ProTooltip from '@/components/ProTooltip'
import { setInviteSettings, updateAccountInviteLink } from '@/store/accounts/actions'
import { Container } from './styles'

const InviteSettings = ({ inviteType, bottomText }) => {
  const dispatch = useDispatch()

  const {
    currentAccount,
    teamsData,
    currentInviteLink: inviteLink,
    inviteSettings,
    isInviteLoading,
    isInviteModalOpen,
  } = useSelector((state) => state.accounts)

  const hasAvailableLicenses = !!currentAccount?.licenses_summary?.available

  const handleUpdateInviteLink = useMemo(
    () =>
      debounce(async (teamId, customText, assignLicense) => {
        const data = {
          teams: teamId ? [teamId] : [],
          custom_text_md: customText || '',
          assign_license: assignLicense,
        }

        await dispatch(updateAccountInviteLink(currentAccount?.id, inviteLink?.id, data))
      }, 300),
    [inviteLink],
  )

  useEffect(() => {
    if (!isInviteModalOpen) return

    dispatch(setInviteSettings('assignLicense', !!currentAccount?.licenses_summary?.available))
  }, [isInviteModalOpen, inviteType])

  return (
    <Container className="invite-settings-copntainer">
      <p className="title">General invite settings</p>

      {!!teamsData?.count && (
        <Select
          className="team-select"
          value={inviteSettings?.teamId}
          placeholder="No team selected"
          notFoundContent="No teams found"
          options={teamsData?.results?.map((t) => ({
            value: t.id,
            label: <TeamItem team={t} size="xsmall" />,
          }))}
          onChange={(value) => {
            dispatch(setInviteSettings('teamId', value))

            if (inviteType === 'link') {
              handleUpdateInviteLink(value, inviteSettings?.customText, inviteSettings?.assignLicense)
            }
          }}
          allowClear
          virtual={false}
        />
      )}

      <Input.TextArea
        value={inviteSettings?.customText}
        placeholder="Custom invitation text"
        allowClear
        style={{
          resize: 'none',
        }}
        onChange={(evt) => {
          dispatch(setInviteSettings('customText', evt.target.value))

          if (inviteType === 'link') {
            handleUpdateInviteLink(inviteSettings?.teamId, evt.target.value, inviteSettings?.assignLicense)
          }
        }}
      />

      <ProTooltip
        title={!hasAvailableLicenses && 'Need more licenses?'}
        content={
          <p className="text">
            Contact us at{' '}
            <a href={`mailto:sales@${isDataWarsHostName ? 'datawars.io' : 'namespace.im'}?subject=Licenses`}>
              sales@{isDataWarsHostName ? 'datawars.io' : 'namespace.im'}
            </a>
            .
          </p>
        }
      >
        <div className="license-info">
          <div className="license-switch">
            <p className="text">Assign licenses to everyone</p>

            <Switch
              checked={inviteSettings?.assignLicense}
              onChange={(isChecked) => {
                dispatch(setInviteSettings('assignLicense', isChecked))

                if (inviteType === 'link') {
                  handleUpdateInviteLink(inviteSettings?.teamId, inviteSettings?.customText, isChecked)
                }
              }}
              loading={isInviteLoading}
              disabled={!currentAccount?.licenses_summary?.available}
            />
          </div>

          <p className="text">
            Licenses available: <b>{currentAccount?.licenses_summary?.available}</b>
          </p>
        </div>
      </ProTooltip>

      {bottomText && <p className="info-text">{bottomText}</p>}
    </Container>
  )
}

export default InviteSettings
