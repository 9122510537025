import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  .ant-modal-content {
    border-radius: 5px;
    overflow: hidden;
    padding: 0px;

    .ant-modal-header {
      padding: 20px 20px 12px;
    }

    .content {
      height: 0px;
      position: relative;
      padding: 0px 0px 56.25%;

      .loading-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        padding-top: 60px;

        .ant-spin {
          margin-bottom: 20px;
          margin-left: -18px;
        }

        .text {
          color: ${(props) => props.theme.colors.gray7};
        }
      }

      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0;
        border: none;
      }
    }
  }

  .ant-modal-close {
    height: 40px;
    width: 40px;
    background-color: ${(props) => props.theme.colors.gray4};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 2px;

    .ant-modal-close-x {
      line-height: inherit;

      svg {
        color: ${(props) => props.theme.colors.gray10};
        font-size: 18px;
      }
    }
  }
`
