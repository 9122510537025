import packageJson from '../../package.json'

const version = packageJson.version
const currentEnv = import.meta.env.REACT_APP_ENVIRONMENT
const isProduction = currentEnv === 'production'
const isDevelopment = currentEnv === 'development'
const isStaging = currentEnv === 'staging'

const hostname = window.location.hostname
const isDataWarsHostName = hostname?.endsWith('datawars.io')

const apiURL = isDataWarsHostName
  ? import.meta.env.REACT_APP_API_BASE_URL
  : import.meta.env.REACT_APP_API_BASE_URL?.replace('datawars.io', 'namespace.im')
const platformURL = isDataWarsHostName
  ? import.meta.env.REACT_APP_PLATFORM_BASE_URL
  : import.meta.env.REACT_APP_PLATFORM_BASE_URL?.replace('datawars.io', 'namespace.im')
const profilesURL = import.meta.env.REACT_APP_PROFILES_BASE_URL
const djangoAdminURL = import.meta.env.REACT_APP_DJANGO_ADMIN_URL

const getPlatformURL = (accountSlug) => {
  if (isDataWarsHostName) return platformURL

  return platformURL.replace('app.', `${accountSlug}.`)
}

export {
  version,
  currentEnv,
  isDevelopment,
  isStaging,
  isProduction,
  isDataWarsHostName,
  apiURL,
  profilesURL,
  djangoAdminURL,
  getPlatformURL,
}
