import styled, { css, keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'
import CustomProjectsEmptyState from '@/assets/images/empty-state-custom-projects.png'
import { device } from '@/themes/breakpoints'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  overflow: auto;
  flex: none;

  .table-header {
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 14px 24px;

    .selected-module-content {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .filters {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      .user-search {
        max-width: 300px;
        width: 100%;

        .ant-input-search-button {
          cursor: default;

          &:hover {
            border-color: ${(props) => props.theme.colors.gray5};
          }

          > div {
            display: none;
          }
        }
      }

      .selectors-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 8px;

        .metric-select {
          max-width: 240px;
          width: 100%;
        }
      }

      .actions {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
    }

    ${(props) =>
      props.$isProjectSelected &&
      css`
        .filters {
          height: 100%;
          max-width: 270px;
          flex-direction: column-reverse;
          align-items: flex-end;
          justify-content: space-between;

          .actions {
            flex-direction: row;
          }
        }
      `}
  }

  .table-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;

    .fade-in-mask {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .overlap-top-left {
      height: 178px;
      width: 280px;
      background-color: ${(props) => props.theme.colors.gray4};
      position: sticky;
      top: 0px;
      left: 0px;
      margin-bottom: -178px;
      z-index: 3;

      &.sm {
        height: 56px;
        margin-bottom: -56px;
      }
    }

    .table {
      width: 100%;
      background-color: ${(props) => props.theme.colors.gray4};
      overflow: auto;
      display: block;

      &.table-header-rotated {
        border-collapse: separate;
        border-spacing: 0;
        position: relative;

        thead {
          background-color: ${(props) => props.theme.colors.gray4};
          position: sticky;
          top: 0px;
          z-index: 2;

          tr {
            th {
              &:first-of-type {
                background-color: ${(props) => props.theme.colors.gray4};
                position: sticky;
                left: 0;
                z-index: 2;
              }

              &.normal {
                min-width: 110px;
                max-width: 110px;

                .head-item-container {
                  height: 54px;
                  background-color: ${(props) => props.theme.colors.gray2};
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 0px 10px;

                  .head-item-content {
                    color: ${(props) => props.theme.colors.gray10};
                    font-weight: 500;
                    line-height: 18px;
                  }
                }
              }

              &.rotated {
                height: 178px;
                position: relative;

                .head-item-container {
                  width: 242px;
                  height: 62px;
                  position: absolute;
                  left: 0px;
                  bottom: 0px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  border-top: 1px solid ${(props) => props.theme.colors.gray5}88;
                  overflow: hidden;
                  padding: 0px 14px 4px 38px;
                  transform: translate(-27px, -49px) rotate(305deg);
                  pointer-events: none;
                  z-index: 2;

                  .head-item-content {
                    color: ${(props) => props.theme.colors.gray10};
                    font-weight: 500;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 0px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    text-align: left;
                    padding: 4px 0px;
                    cursor: pointer;
                    pointer-events: all;

                    &:hover {
                      color: ${(props) => props.theme.colors.gray7};
                    }
                  }
                }

                &.empty {
                  .head-item-container {
                    cursor: default;

                    &:hover {
                      background: none;
                    }
                  }
                }

                &:last-of-type {
                  .head-item-container {
                    /* border-bottom: 1px solid ${(props) => props.theme.colors.gray5}88; */
                  }
                }
              }
            }
          }
        }

        tbody {
          position: relative;

          tr {
            th {
              &.row-header {
                height: 56px;
                min-width: 280px;
                max-width: 280px;
                background-color: ${(props) => props.theme.colors.gray1};
                color: ${(props) => props.theme.colors.gray9};
                font-weight: 400;
                text-align: left;
                overflow: auto;
                padding: 0 10px;
                border-left: 1px solid ${(props) => props.theme.colors.gray4};
                border-right: 1px solid ${(props) => props.theme.colors.gray4};
                border-top: 1px solid ${(props) => props.theme.colors.gray4};
                position: sticky;
                left: 0;
                z-index: 2;
              }
            }

            td.stat-block {
              min-width: 76px;
              max-width: 76px;
              color: ${(props) => props.theme.colors.gray9};
              background-color: ${(props) => props.theme.colors.gray2};
              font-size: 13px;
              line-height: 16px;
              position: relative;
              text-align: center;
              border-left: 1px solid ${(props) => props.theme.colors.gray4};
              border-top: 1px solid ${(props) => props.theme.colors.gray4};
              padding: 10px 5px;
              z-index: 1;

              &:first-of-type {
                border-left: none;
              }

              &:last-of-type {
                border-right: 1px solid ${(props) => props.theme.colors.gray4};
              }

              &.has-content {
                cursor: pointer;
                transition: 0.3s opacity;

                .metric-text {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 4px;

                  .check-icon {
                    width: 18px;
                    height: 18px;
                    color: ${(props) => props.theme.colors.gray13};
                    padding: 0px;
                  }
                }

                &:hover {
                  opacity: 0.8;
                }
              }
            }

            &:first-of-type {
              th.row-header {
                /* border-radius: 10px 0px 0px 0px; */
              }
            }

            &:last-of-type {
              th.row-header {
                border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
                /* border-radius: 0px 0px 0px 10px; */
              }

              td.stat-block {
                border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
              }
            }
          }
        }
      }
    }

    .pagination-container {
      position: sticky;
      left: 0px;
      margin: 16px 0px;
      z-index: 1;

      .ant-pagination {
        /* display: flex;
        justify-content: flex-end; */

        .ant-pagination-item:not(.ant-pagination-item-active) {
          background-color: transparent;
        }
      }
    }
  }

  .loading-container {
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 14px;
    flex: none;
  }

  .empty-state-container {
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    animation: 1s ${fadeInAnimation};

    .empty-state-content {
      height: 100%;
      width: 100%;
      background: url(${CustomProjectsEmptyState}) no-repeat center center;

      > .header {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 24px 36px;

        .container {
          max-width: 240px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .title-container {
            max-width: 230px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;

            .icon {
              min-width: 24px;
              font-size: 24px;
              color: ${(props) => props.theme.colors.geekblue5};
            }

            .title {
              margin: 0px 0px 12px;
            }
          }

          .text {
            color: ${(props) => props.theme.colors.gray7};
            margin: 0px 0px 20px;
          }

          .link {
            color: ${(props) => props.theme.colors.gray7};
            text-decoration: underline;
          }

          > button {
            margin-top: 18px;
          }
        }
      }
    }
  }

  @media ${device.mdDown} {
    gap: 8px;

    .table-header {
      max-height: 90px;
      height: 100%;
      border-top: 1px solid ${(props) => props.theme.colors.gray4};
      padding: 0px 14px;

      &.is-selected {
        flex-direction: column;
      }

      .selected-module-content {
        height: 100%;
        margin-top: 4px;

        .module-info {
          .module-image {
            display: none;
          }

          .content {
            flex-direction: row;
            gap: 4px;

            .pre-title,
            .tags-container {
              display: none;
            }

            .title {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }

      .filters {
        max-width: 100%;
        flex-direction: column;

        .user-search {
          max-width: initial;
        }

        .dw-btn {
          display: none;
        }

        .selectors-container {
          .metric-select {
            max-width: 50%;
          }
        }
      }
    }

    .table-container {
      .pagination-container {
        margin: 8px 0px;
      }
    }
  }
`
