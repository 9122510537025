import styled, { css } from 'styled-components'

export const Container = styled.div`
  height: 32px;
  position: relative;
  display: flex;
  margin-right: 12px;

  ${(props) =>
    props.$isTeams &&
    css`
      .ant-avatar {
        border: solid 3px transparent;
        border-radius: 50%;
        background-image: linear-gradient(white, white),
          radial-gradient(circle at top left, ${props.theme.colors.purple8}, ${props.theme.colors.purple3});
        background-origin: border-box;
        background-clip: padding-box, border-box;
      }
    `}

  ${(props) =>
    props.$isPro !== undefined &&
    css`
      .ant-avatar {
        clip-path: path(
          'M28.1322 7.74545C28.4679 8.35192 28.0295 9.07202 27.3484 9.20093C22.0255 10.2084 18 14.8841 18 20.5C18 23.1484 18.8952 25.5876 20.3996 27.5317C20.8232 28.0792 20.6739 28.9071 20.0215 29.1389C18.4515 29.6965 16.7612 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C20.6528 0 25.5753 3.12693 28.1322 7.74545Z'
        );
      }
    `}

  .ant-avatar {
    .ant-avatar-string {
      color: ${(props) => props.theme.colors.gray1};
    }
  }

  .icon {
    position: absolute;
    bottom: calc(50% - 12px);
    right: -5px;

    &.owner-badge {
      width: 20px;
      height: 20px;
      color: ${(props) => props.theme.colors.gray1};
      background-color: ${(props) => props.theme.colors.purple8};
      border: 1px solid ${(props) => props.theme.colors.gray1};
      border-radius: 50%;
      bottom: -4px;
      padding: 4px;
    }

    &.basic-badge {
      width: 16px;
      filter: drop-shadow(0px 1px 4px #6e7bf4);
    }

    &.pro-badge {
      width: 17px;
      filter: drop-shadow(0px 1px 4px #61b3a2);
    }
  }

  ${(props) =>
    props.$size === 'small' &&
    css`
      height: 24px;

      .ant-avatar {
        width: 24px;
        height: 24px;
        line-height: 22px;
        border-radius: 50%;
        clip-path: none;
      }

      .icon {
        bottom: calc(50% - 11px);
        right: -5px;

        &.basic-badge {
          height: 11px;
          width: 11px;
        }

        &.pro-badge {
          height: 10px;
          width: 11px;
        }
      }
    `}

  ${(props) =>
    props.$size === 'large' &&
    css`
      height: 48px;

      .ant-avatar {
        width: 48px;
        height: 48px;
        line-height: 22px;
        border-radius: 50%;
        clip-path: none;
      }

      .icon {
        bottom: calc(50% - 24px);
        right: -3px;

        &.basic-badge {
          height: 18px;
          width: 18px;
        }

        &.pro-badge {
          height: 16px;
          width: 17px;
        }
      }
    `}
`
