import { useSelector } from 'react-redux'
import { useNavigate, useRouteError } from 'react-router-dom'
import { getPlatformURL } from '@/helpers/env'
import Layout1 from '@/layouts/Layout1'
import ErrorCard from './components/ErrorCard'
import { Container } from './styles'

const ErrorPage = () => {
  const navigate = useNavigate()
  const error = useRouteError()

  const { currentAccount } = useSelector((state) => state.accounts)

  const selfLaunchIsEnabled = currentAccount?.config?.launch?.self_launch

  const refreshPage = () => {
    window.location.reload()
  }

  const goToLearnPlatform = () => {
    navigate(getPlatformURL(currentAccount?.slug), { replace: true })
  }

  return (
    <Layout1 navbar>
      <Container>
        <ErrorCard
          title={'An unexpected error has occurred.'}
          text={error.statusText || error.message}
          buttonText={'Refresh this page'}
          buttonOnClick={refreshPage}
          bottomText={selfLaunchIsEnabled && 'or go to Learn platform'}
          bottomOnClick={selfLaunchIsEnabled && goToLearnPlatform}
        />
      </Container>
    </Layout1>
  )
}

export default ErrorPage
