import { Link } from 'react-router-dom'
import Button from '@/components/Button'
import ErrorImage from '@/assets/images/error.gif'
import { Container } from './styles'

const ErrorCard = ({
  title,
  text,
  primaryButtonText = 'Go to Dashboard',
  primaryButtonLinkTo = '/',
  buttonText,
  buttonOnClick,
  bottomText,
  bottomOnClick,
}) => {
  return (
    <Container className="error-card-container">
      <img src={ErrorImage} alt={title} />

      <h3 className="title">{title}</h3>
      {!!text && <p className="text-body">{text}</p>}

      <div className="actions">
        <Link to={primaryButtonLinkTo}>
          <Button className="button" type="primary">
            {primaryButtonText}
          </Button>
        </Link>

        {!!buttonText && (
          <Button className="button" onClick={buttonOnClick}>
            {buttonText}
          </Button>
        )}
      </div>

      {!!bottomText && (
        <p className="bottom-text" onClick={bottomOnClick}>
          {bottomText}
        </p>
      )}
    </Container>
  )
}

export default ErrorCard
