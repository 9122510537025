import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { Input, Table, Spin, Empty } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import Button from '@/components/Button'
import FadeIn from '@/components/FadeIn'
import MemberAvatar from '@/components/MemberAvatar'
import { fetchAnalyticsLeaderboard, setUserSearchText, setInviteModalOpen } from '@/store/accounts/actions'
import Rank1 from '@/assets/images/leaderboard/1.png'
import Rank2 from '@/assets/images/leaderboard/2.png'
import Rank3 from '@/assets/images/leaderboard/3.png'
import { Container } from './styles'

const LeaderboardTable = ({ size, isPreview }) => {
  const dispatch = useDispatch()

  const { pageSize: defaultPageSize } = useSelector((state) => state.app)
  const {
    allUsers,
    currentAccount: account,
    analyticsLeaderboard,
    userSearchText,
    filteredTeam,
    selectedAnalyticsTab,
    isLoading: isAccountsLoading,
  } = useSelector((state) => state.accounts)

  const inviteFulfillmentIsEnabled = account?.config?.fulfillment?.invite

  const teamId = filteredTeam === 'all' ? undefined : filteredTeam === 'not-in-teams' ? 'None' : filteredTeam

  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [sortedInfo, setSortedInfo] = useState({ order: 'descend', field: 'finished_activities' })

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'ranking',
      className: 'ranking-col',
      fixed: 'left',
      render: (text, record, index) => {
        const rank = index + 1

        if (sortedInfo?.order === 'descend') {
          if (rank === 1) return <img className="ranking-image" src={Rank1} alt="Ranking 1" />
          if (rank === 2) return <img className="ranking-image" src={Rank2} alt="Ranking 2" />
          if (rank === 3) return <img className="ranking-image" src={Rank3} alt="Ranking 3" />
        }

        if (sortedInfo?.order === 'ascend') {
          const size = allUsers?.length || 0
          if (rank === size) return <img className="ranking-image" src={Rank1} alt="Ranking 1" />
          if (rank === size - 1) return <img className="ranking-image" src={Rank2} alt="Ranking 2" />
          if (rank === size - 2) return <img className="ranking-image" src={Rank3} alt="Ranking 3" />
        }

        return rank
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      className: 'name-col',
      fixed: 'left',
    },
    {
      title: 'Finished activities',
      dataIndex: 'finished_activities',
      className: 'finished-activities-col',
      sorter: !isPreview ? (a, b) => a.finished_activities - b.finished_activities : null,
      sortOrder: !isPreview && sortedInfo.field === 'finished_activities' ? sortedInfo.order : null,
    },
    ...(isPreview
      ? []
      : [
          {
            title: 'Finished modules',
            dataIndex: 'finished_modules',
            sorter: (a, b) => a.finished_modules - b.finished_modules,
            sortOrder: sortedInfo.field === 'finished_modules' ? sortedInfo.order : null,
          },
          {
            title: 'Lab time (min)',
            dataIndex: 'lab_running_time',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.lab_running_time - b.lab_running_time,
            sortOrder: sortedInfo.field === 'lab_running_time' ? sortedInfo.order : null,
          },
        ]),
  ]

  const data = analyticsLeaderboard?.results
    ?.slice(0, isPreview ? 5 : undefined)
    ?.map((item, i) => {
      const user = allUsers?.find((userData) => userData?.user?.id === item?.user_id)?.user

      return {
        key: user?.id,
        ranking: i + 1,
        name: (
          <MemberAvatar
            lastName={user?.last_name}
            firstName={user?.first_name}
            email={!isPreview && user?.email}
            showName
            avatarUrl={user?.avatar_url}
            externalId={user?.external_id}
          />
        ),
        finished_activities: item?.finished_activities || 0,
        finished_modules: item?.finished_modules || 0,
        lab_running_time: parseInt((item?.lab_running_time || 0) / 60),
      }
    })
    ?.sort((a, b) => b.finished_activities - a.finished_activities)

  const handleTableChange = (pagination, filters, sorter) => {
    const { current: page, pageSize } = pagination
    const ordering = `${sorter?.order === 'ascend' ? '' : '-'}${sorter?.field}`

    setPageSize(pageSize)
    setSortedInfo(sorter)

    dispatch(
      fetchAnalyticsLeaderboard(account?.id, {
        search: userSearchText,
        team_id: teamId,
        ordering,
        page_size: pageSize,
        page,
      }),
    )
  }

  const handleUserSearch = useMemo(
    () =>
      debounce((evt) => {
        const search = evt?.target?.value?.toLowerCase()
        const ordering = `${sortedInfo?.order === 'ascend' ? '' : '-'}${sortedInfo?.field}`

        dispatch(
          fetchAnalyticsLeaderboard(account?.id, {
            search,
            team_id: teamId,
            ordering,
            page_size: pageSize,
          }),
        )
      }, 300),
    [filteredTeam, sortedInfo],
  )

  useEffect(() => {
    if (!account || selectedAnalyticsTab !== 'leaderboard') return

    const ordering = `${sortedInfo?.order === 'ascend' ? '' : '-'}${sortedInfo?.field}`

    dispatch(
      fetchAnalyticsLeaderboard(account?.id, {
        team_id: teamId,
        ordering,
        page_size: pageSize,
      }),
    )
  }, [selectedAnalyticsTab, account, filteredTeam]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className="leaderboard-table-container">
      {!isPreview && !!allUsers?.length && (
        <div className="table-header">
          <h5 className="title">Top members — last 30 days</h5>

          <div className="filters">
            <Input.Search
              className="user-search"
              defaultValue={userSearchText}
              value={userSearchText}
              onChange={(evt) => {
                dispatch(setUserSearchText(evt?.target?.value?.toLowerCase()))
                handleUserSearch(evt)
              }}
              allowClear
              placeholder="Search member..."
              loading={isAccountsLoading}
            />
          </div>
        </div>
      )}

      {!allUsers && isAccountsLoading ? (
        <div className="loading-container">
          <Spin size="large" />
          <p>Loading analytics...</p>
        </div>
      ) : allUsers?.length ? (
        <div className="table-container">
          <FadeIn>
            <Table
              className="leaderboard-table"
              columns={columns}
              dataSource={data}
              size={size}
              loading={!allUsers || isAccountsLoading}
              onChange={handleTableChange}
              locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No members found" /> }}
              pagination={
                !isPreview && {
                  pageSize,
                  total: analyticsLeaderboard?.count,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} members`,
                }
              }
              scroll={{
                y: true,
                x: true,
              }}
            />
          </FadeIn>
        </div>
      ) : (
        <div className="empty-state-container">
          <div className="empty-state-content">
            <div className="header">
              <div className="container">
                <div className="title-container">
                  <UserOutlined className="icon" />

                  <h4 className="title">You don't have any members yet.</h4>
                </div>

                {inviteFulfillmentIsEnabled && (
                  <>
                    <h4 className="text">Invite them to join now!</h4>

                    <Button type="secondary" onClick={() => dispatch(setInviteModalOpen(true))}>
                      Invite members
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  )
}

export default LeaderboardTable
