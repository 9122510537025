import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isDataWarsHostName } from '@/helpers/env'
import RouteTracking from '@/router/RouteTracking'
import PrivateRoute from '@/router/PrivateRoute'
import ErrorPage from '@/pages/ErrorPage'
import AccountsPage from '@/pages/AccountsPage'
import AccountCreationPage from '@/pages/AccountCreationPage'
import MainPage from '@/pages/MainPage'
import AuthPage from '@/pages/AuthPage'
import NotFoundPage from '@/pages/NotFoundPage'

const getDataWarsRoutes = (redirectTo) => [
  {
    path: '/login',
    element: <AuthPage />,
  },
  {
    path: '/register',
    element: <AuthPage />,
  },
  {
    path: '/forgot-password',
    element: <AuthPage />,
  },
  {
    path: '/reset-password',
    element: <AuthPage />,
  },
  {
    path: '/not-found',
    element: <NotFoundPage />,
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        path: '/accounts',
        element: <AccountsPage />,
      },
      {
        path: '/create-account',
        element: <AccountCreationPage />,
      },
      {
        path: '/accounts/:accountId/:section?',
        element: <MainPage />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate replace to={redirectTo || '/not-found'} />,
  },
]

const getNamespaceRoutes = (redirectTo) => [
  {
    path: '/not-found',
    element: <NotFoundPage />,
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        path: '/accounts',
        element: <AccountsPage />,
      },
      {
        path: '/accounts/:accountId/:section?',
        element: <MainPage />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate replace to={redirectTo || '/not-found'} />,
  },
]

const getRoutes = (redirectTo) => {
  return [
    {
      element: <RouteTracking />,
      errorElement: <ErrorPage />,
      children: isDataWarsHostName ? getDataWarsRoutes(redirectTo) : getNamespaceRoutes(redirectTo),
    },
  ]
}

const Router = () => {
  const { userProfile } = useSelector((state) => state.users)

  const pathname = window.location.pathname
  const urlAccount = pathname?.startsWith('/accounts') && pathname?.split('/')?.[2]

  const isSuperAccountOwner = userProfile?.permissions?.includes('teams.super_account_owner')
  const isAccountOwner = userProfile?.accounts?.some((a) => a?.role === 'owner')
  const userOwnerAccounts = userProfile?.accounts?.filter((a) => a?.role === 'owner')?.map((a) => a.id)

  let baseAccount
  let redirectTo

  switch (true) {
    case isSuperAccountOwner:
      redirectTo = '/accounts'
      break
    case isAccountOwner:
      baseAccount = userOwnerAccounts?.includes(urlAccount) && urlAccount

      redirectTo = baseAccount
        ? `/accounts/${baseAccount}`
        : userOwnerAccounts?.length === 1
          ? `/accounts/${userOwnerAccounts?.[0]}`
          : '/accounts'
      break
    default:
      redirectTo = isDataWarsHostName ? (userProfile ? '/create-account' : '/login') : '/not-found'
  }

  const router = createBrowserRouter(getRoutes(redirectTo))
  return <RouterProvider router={router} />
}

export default Router
