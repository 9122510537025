import { useState, useEffect } from 'react'
import { Skeleton } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import getIconByUrl from '@/utils/getIconByUrl'
import FadeIn from '@/components/FadeIn'
import Button from '@/components/Button'
import { Container } from './styles'

const PlaygroundInfo = ({ playground, isLoading, handleBack }) => {
  const [mainIcon, setMainIcon] = useState(null)

  useEffect(() => {
    if (!playground) return

    getIconByUrl({
      iconUrl: playground?.data_sources?.[0]?.theme?.logo_url || 'ant-FileTextOutlined',
      className: 'main-icon',
      onReady: setMainIcon,
    })
  }, [playground])

  if (isLoading)
    return (
      <Container className="playground-info is-loading">
        <div className="content">
          <div className="actions">
            {handleBack && (
              <Button className="back-button" type="text" size="small" onClick={handleBack}>
                <ArrowLeftOutlined /> View all
              </Button>
            )}
          </div>

          <Skeleton active title={false} paragraph={{ rows: 3 }} />
        </div>
      </Container>
    )

  return (
    <FadeIn>
      <Container className="playground-info" $playgroundBackgroundImage={playground?.image_url}>
        <div className="image-container">{mainIcon}</div>

        <div className="content">
          <div className="actions">
            {handleBack && (
              <Button className="back-button" type="text" size="small" onClick={handleBack}>
                <ArrowLeftOutlined /> View all
              </Button>
            )}
          </div>

          <h3 className="title">{playground?.name}</h3>
        </div>
      </Container>
    </FadeIn>
  )
}

export default PlaygroundInfo
