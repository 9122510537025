import { Tooltip } from 'antd'
import { capitalize } from '@/utils/functions'
import ProfilePicture from '@/components/ProfilePicture'
import { Container } from './styles'

const MemberAvatar = ({ lastName, firstName, externalId, email, avatarUrl, showName, showTooltip, isOwner, size }) => {
  const name = lastName
    ? `${capitalize(firstName)} ${capitalize(lastName)}`
    : firstName
      ? capitalize(firstName)
      : externalId || 'No name'

  return (
    <Container className="member-avatar" $showName={showName}>
      <Tooltip title={showTooltip && name}>
        <ProfilePicture
          src={avatarUrl}
          alt={capitalize(firstName)}
          isOwner={isOwner}
          defaultPicture="letter"
          disableTooltip
          size={size}
        />

        <div className="name-container">
          {showName && <span className="name">{name}</span>}
          {email && <span className="email">{email}</span>}
        </div>
      </Tooltip>
    </Container>
  )
}

export default MemberAvatar
